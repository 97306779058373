import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-opening-hour',
	templateUrl: './opening-hour.component.html',
	styleUrls: ['./opening-hour.component.scss'],
})
export class OpeningHourComponent {
	@Input('openingHourData') openingHourData;
}
