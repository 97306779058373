<div
	id="about-us"
	class="about-us-container section-padding"
	fxLayout="row"
	fxLayout.sm="column"
	fxLayoutAlign="center"
	fxLayoutAlign.sm="center"
	fxLayout.xs="column"
	fxLayoutAlign.xs="center"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
	style="scroll-margin-top: 88px"
>
	<div class="image" fxFlex="50%">
		<img
			src="{{ imageForm?.PropertyValue?.value[0]?.FileUrl }}"
			onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
			alt="card-img"
		/>
	</div>
	<div class="description" fxFlex="50%">
		<p class="small-title">{{ 'ABOUT_US' | translate }}</p>
		<p class="main-title h1-48px" [innerHTML]="mainTitleForm?.PropertyValue.value"></p>
		<div class="outlet-head-info" *ngIf="outletHeadEmail" fxLayout="column" fxLayoutGap="5px">
			<p class="name">{{ outletHeadName }}</p>
			<p class="role">{{ outletHeadRole }}</p>
			<p class="email">
				<a href="mailto:{{ outletHeadEmail }}" style="cursor: pointer">{{
					outletHeadEmail?.replace('@', '[at]')
				}}</a>
			</p>
		</div>
		<div class="restaurent-info" fxLayout="row wrap" fxLayoutGap="20px">
			<div *ngIf="totalSeats" class="seat-number" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ totalSeats + ' ' + ('PLACES' | translate) }}</p>
			</div>
			<div class="take-away" fxLayout="row" *ngIf="isTakeAwayAvailable">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'TAKE_AWAY_OFFER' | translate }}</p>
			</div>
			<div class="parking" fxLayout="row" *ngIf="isParkingAvailable">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'PARKING_AVAILABLE' | translate }}</p>
			</div>
			<div *ngIf="isConferenceEquipmentAvailable" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'CONFERENCE_TECHNOLOGY_AVAILABLE' | translate }}</p>
			</div>
			<div *ngIf="isGardenAvailable" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'GARDEN_AVAILABLE' | translate }}</p>
			</div>

			<div *ngIf="isInnerCourtyardAvailable" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'INNER_COUNTRY_YARD_AVAILABLE' | translate }}</p>
			</div>

			<div *ngIf="isParkingAvailable" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'PARKING_AVAILABLE' | translate }}</p>
			</div>

			<div *ngIf="isTakeAwayAvailable" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'TAKE_AWAY_AVAILABLE' | translate }}</p>
			</div>
			<div *ngIf="isTerraceAvailable" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ 'TERRACE_AVAILABLE' | translate }}</p>
			</div>

			<div *ngIf="isGardenAvailable && NumberOfSeatsInGarden > 0" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ NumberOfSeatsInGarden + ' ' + ('SEAT_IN_GARDEN' | translate) }}</p>
			</div>

			<div *ngIf="isInnerCourtyardAvailable && NumberOfSeatsInInnerCourtyard" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ NumberOfSeatsInInnerCourtyard + ' ' + ('SEAT_IN_COUNTRY_YEARD' | translate) }}</p>
			</div>

			<div *ngIf="isTerraceAvailable && NumberOfSeatsInTerrace" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ NumberOfSeatsInTerrace + ' ' + ('SEAT_IN_TERRACE' | translate) }}</p>
			</div>
			<div *ngIf="NumberOfSeatsInside > 0" class="public" fxLayout="row">
				<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
				<p>{{ NumberOfSeatsInside + ' ' + ('SEAT_IN_INSIDE' | translate) }}</p>
			</div>
		</div>
		<p class="sub-title" [innerHTML]="subTitleForm?.PropertyValue.value"></p>
		<app-opening-hour [openingHourData]="openingHours"></app-opening-hour>
		<button
			mat-flat-button
			class="button"
			style="cursor: pointer"
			(click)="LearnMoreLink()"
			fxLayout="row"
			fxLayoutAlign="center center"
		>
			<p>{{ 'MORE_KNOWLEDGE' | translate }}</p>
			<mat-icon class="material-symbols-outlined"> chevron_right </mat-icon>
		</button>
	</div>
</div>
