import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'priceFormatter',
})
export class PriceFormatterPipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}

	transform(value: string | number): string {
		const lang = this.translateService.currentLang;
		let formattedValue = value.toString();
		if (lang === 'de') {
			formattedValue = formattedValue?.replace('.', ',');
		}
		return formattedValue;
	}
}
