<div class="opening-hour" *ngIf="openingHourData?.length > 0">
	<div>
		<div class="left-side hour-list" fxLayout="column">
			<ng-container *ngFor="let item of openingHourData; let last = last">
				<div fxLayout="row wrap" fxLayoutAlign="space-between">
					<div fxFlex="40" fxFlex.xs="50" class="days" fxLayout="row" fxLayoutAlign="start center">
						<img src="../../../../assets/icons/calendar-today.svg" alt="calendar_today" />
						<p>{{ item.WeekDay }}</p>
					</div>
					<div
						fxFlex="60"
						fxFlex.xs="50"
						class="time"
						fxLayout="row"
						fxLayout.xs="column"
						fxLayoutAlign="start center"
						fxLayoutAlign.xs="space-between end"
					>
						<div fxLayout="row" fxFlex="30">
							<p>{{ item.OpeningTime }} - {{ item.ClosingTime }}</p>
						</div>
						<div fxLayout="row" class="category-name" *ngIf="item?.CategoryName">
							<span fxShow fxShow.xs="false" fxShow.gt-xs="true" class="line"></span>
							<p>{{ item.CategoryName }}</p>
						</div>
					</div>
				</div>
				<div fxShow fxShow.xs="true" fxShow.gt-xs="false" *ngIf="!last" class="horizontal-line"></div>
			</ng-container>
		</div>
	</div>
</div>
