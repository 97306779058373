import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-outlet-info',
	templateUrl: './outlet-info.component.html',
	styleUrls: ['./outlet-info.component.scss'],
})
export class OutletInfoComponent implements OnInit {
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('mainTitle') mainTitle: string = 'Alle Fakten zum SV Restaurant';
	@Input('subTitle') subTitle: string =
		'Hier wäre die Idee, dass ihr die wichtigsten Facts & Figures über das Restaurant notieren könnt. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.';
	@Input('form') form: any = null;

	@Input('sectionImage') sectionImage: any[] = [
		{
			FileId: '',
			FileUrl:
				'https://selisefalcon.blob.core.windows.net/public-ecap-falcon/dev/88878FC2-949B-419F-87A5-52CDEB8A1334/b4da0b69-a77e-444b-9134-dc24935b62a5/1a84b8b0-7900-4903-b4d9-fb232b296a0a/outlet-image.png',
		},
	];

	mainTitleForm: any = null;
	subTitleForm: any = null;
	imageForm: any = null;
	totalSeats: any = null;
	isOutletInfo1: any = null;
	isOutletInfo2: any = null;

	outletHeadName: any = null;
	outletHeadEmail: any = null;
	outletHeadRole: any = null;

	isConferenceEquipmentAvailable: any = null;
	isGardenAvailable: any = null;
	isInnerCourtyardAvailable: any = null;
	isParkingAvailable: any = null;
	isTakeAwayAvailable: any = null;
	isTerraceAvailable: any = null;
	NumberOfSeatsInGarden: any = null;
	NumberOfSeatsInInnerCourtyard: any = null;
	NumberOfSeatsInTerrace: any = null;
	NumberOfSeatsInside: any = null;
	outletDescriptionForm: any = null;
	isEquipmentDivHide: boolean = false;

	openingHours: any = null;
	categoryHours: any = null;

	constructor(private router: Router, private appSectionTemplateService: AppSectionTemplateService) {}

	ngOnInit(): void {
		this.proccessDataForDisplay();
	}

	proccessDataForDisplay(): void {
		if (this.form != null) {
			this.form?.MetadataList?.controls[0]?.controls.forEach((item) => {
				if (item.controls.PropertyKey.value == 'MAIN_TITLE') this.mainTitleForm = item.controls;
				else if (item.controls.PropertyKey.value == 'SUB_TITLE') this.subTitleForm = item.controls;
				else if (item.controls.PropertyKey.value === 'ImageFiles') {
					this.imageForm = item.controls;
				}
			});
			this.totalSeats = this.form?.OutletMetadata.value?.TotalSeats;
			this.isTakeAwayAvailable = this.form?.OutletMetadata.value?.IsTakeAwayAvailable;
			this.isParkingAvailable = this.form?.OutletMetadata.value?.IsParkingAvailable;
			this.outletHeadName = this.form?.OutletMetadata.value?.OutletManagerName;
			this.outletHeadEmail = this.form?.OutletMetadata.value?.OutletManagerEmail;
			this.outletHeadRole = 'Restaurant Manager';

			this.isConferenceEquipmentAvailable =
				this.form?.OutletMetadata.value?.IsConferenceEquipmentAvailable || false;
			this.isGardenAvailable = this.form?.OutletMetadata.value?.IsGardenAvailable || false;
			this.isInnerCourtyardAvailable = this.form?.OutletMetadata.value?.IsInnerCourtyardAvailable || false;
			this.isParkingAvailable = this.form?.OutletMetadata.value?.IsParkingAvailable || false;
			this.isTakeAwayAvailable = this.form?.OutletMetadata.value?.IsTakeAwayAvailable || false;
			this.isTerraceAvailable = this.form?.OutletMetadata.value?.IsTerraceAvailable || false;
			this.NumberOfSeatsInGarden = this.form?.OutletMetadata.value?.NumberOfSeatsInGarden || 0;
			this.NumberOfSeatsInInnerCourtyard = this.form?.OutletMetadata.value?.NumberOfSeatsInInnerCourtyard || 0;
			this.NumberOfSeatsInTerrace = this.form?.OutletMetadata.value?.NumberOfSeatsInTerrace || 0;
			this.NumberOfSeatsInside = this.form?.OutletMetadata.value?.NumberOfSeatsInside || 0;

			this.openingHours = this.form?.OutletMetadata.value?.OpeningHours;
			this.categoryHours = this.form?.OutletMetadata.value?.CategoryHours;
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'about-us');
		}

		if (this.mainTitleForm == null)
			this.mainTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.mainTitle).controls;
		if (this.subTitleForm == null)
			this.subTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.subTitle).controls;
		if (this.imageForm === null)
			this.imageForm = this.appSectionTemplateService.createPropertyFormGroup(this.sectionImage).controls;

		if (this.router.url.includes('template-preview')) {
			this.outletHeadName = 'Restaurant Manager Name';
			this.outletHeadEmail = 'Restaurant Manager Email';
			this.outletHeadRole = 'Restaurant Manager';
		}

		if (!this.router.url.includes('editor')) {
			const targetText = 'SV Restaurant';
			const spanWrap = `<span class="nowrap-text">${targetText}</span>`;
			if (this.mainTitleForm?.PropertyValue?.value) {
				this.mainTitleForm.PropertyValue.value = this.mainTitleForm?.PropertyValue.value.replace(
					targetText,
					spanWrap
				);
			}
		}

		this.isEquipmentDivHide = !this.findOutEquipmentDivAvailability();
	}

	LearnMoreLink() {
		let isMicrosite = !(this.router.url.includes('template-preview') || this.router.url.includes('preview'));

		if (isMicrosite) {
			this.router.navigateByUrl(`${this.form?.DomainName.value}/about-us`);
		} else {
			let learnMoreRoute = this.router.url.split('/');
			this.router.navigateByUrl(`${learnMoreRoute[1]}/${learnMoreRoute[2]}/about-us`);
		}
	}

	findOutEquipmentDivAvailability() {
		return (
			!this.isConferenceEquipmentAvailable &&
			!this.isGardenAvailable &&
			!this.isInnerCourtyardAvailable &&
			!this.isParkingAvailable &&
			!this.isTakeAwayAvailable &&
			!this.isTerraceAvailable &&
			this.NumberOfSeatsInGarden == 0 &&
			this.NumberOfSeatsInInnerCourtyard == 0 &&
			this.NumberOfSeatsInTerrace == 0 &&
			this.NumberOfSeatsInside == 0
		);
	}
}
