import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-outlet-list',
	templateUrl: './outlet-list.component.html',
	styleUrls: ['./outlet-list.component.scss'],
})
export class OutletListComponent implements OnInit, AfterViewInit {
	@Input('outletData') outletData: any = null;
	@Input('selectedOutlet') selectedOutlet: any = null;
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('form') form: any = null;
	@Output('outletChangeEvent') outletChangeEvent: EventEmitter<any> = new EventEmitter();

	constructor(private appSectionTemplateService: AppSectionTemplateService) {}

	ngOnInit(): void {
		if (this.form != null) {
			this.outletData = this.form?.OutletList?.value;
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'oultet-list');
		}
	}

	ngAfterViewInit(): void {
		this.appSectionTemplateService.scrollToSelectedOutleName();
	}

	onOutletSelection(outlet: any, index) {
		this.outletChangeEvent.emit(outlet);
		if (this.selectedOutlet != null && this.form != null) {
			this.selectedOutlet.setValue(outlet);
		} else {
			this.selectedOutlet = { value: outlet };
		}

		this.appSectionTemplateService.outletChangeDetails = {
			isLastOutlet: index == this.outletData.length - 1,
			isChanged: true,
		};
	}
}
