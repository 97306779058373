<div
	id="card-template"
	class="section-padding"
	style="scroll-margin-top: 88px; background: #e9e9e9"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div class="container">
		<div fxHide fxShow.sm fxShow.xs class="title" [innerHtml]="mainTitleForm.PropertyValue.value"></div>
		<div class="card" fxLayout="row" fxLayout.lt-md="column" ngClass.sm="tablet-display" ngClass.xs="mobile-display">
			<div class="details" fxFlex.lt-md="100%" fxFlex="50%">
				<div fxHide fxShow.gt-sm class="title" [innerHtml]="mainTitleForm.PropertyValue.value"></div>
				<div class="body" [innerHtml]="bodyTextForm.PropertyValue.value"></div>
				<button
					*ngIf="buttonTextForm?.PropertyValue?.value?.length >= 1"
					mat-flat-button
					class="button mt-32"
					(click)="onLinkToClick(linkToButtonForm?.PropertyValue?.value || buttonURL)"
				>
					<p>{{ buttonTextForm?.PropertyValue?.value || buttonText }}</p>
					<mat-icon class="material-symbols-outlined"> chevron_right </mat-icon>
				</button>
			</div>
			<div class="image" fxFlex.lt-md="100%" fxFlex="50%">
				<img
					height="100%"
					width="100%"
					src="{{ ImageFilesForm?.PropertyValue?.value[0]?.FileUrl }}"
					onerror="this.src='../../../assets/images/dummy-image-square.jpg';"
					alt="card-img"
				/>
			</div>
		</div>
		<div class="video-container">
			<iframe id="myframe" class="responsive-iframe"> </iframe>
		</div>
		<div
			fxLayout
			fxLayoutAlign.gt-sm="center center"
			class="video-body"
			style="color: #262626"
			[innerHtml]="videoBodyText"
		></div>
	</div>
</div>
