import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

const header = new HttpHeaders({
	'Content-Type': 'application/json',
});

@Injectable()
export class LandingPageService {
	commonOptions: any = {
		headers: header,
		withCredentials: true,
		observe: 'response',
	};

	pageDataForm: FormGroup;
	selectedLanguageForm: any;
	selectedLanguage: string =
		localStorage.getItem('landing-page-language') != undefined ? localStorage.getItem('landing-page-language') : 'de';
	languageList: string[] = ['en', 'de'];

	_pageData: EventEmitter<any> = new EventEmitter();

	addPageData(data: any) {
		this._pageData.emit(data);
	}

	getPageDataEmitter() {
		return this._pageData;
	}

	constructor(private _fb: FormBuilder) {}

	findDefaultPageName(pageData) {
		return pageData.find((x) => x.IsDefault == true)?.MicrositePageName;
	}

	getPageList(pageData) {
		let list = [];
		pageData.forEach((x) => {
			list.push(x.MicrositePageName);
		});
		return list;
	}

	findCurrentPageSectionTemplateIds(data, pageName) {
		return data.controls.find((x) => x.value.PageName == pageName)?.controls?.Sections;
	}

	processGlobalPageData(data) {
		let proccessedData = [];
		data.forEach((x) => {
			proccessedData.push({
				MicrositePageName: x.MicrositePageInfo.MicrositePageName,
				SectionTemplates: x.Sections.map((s) => {
					return {
						Category: s.Category,
						ImageCount: s.ImageCount,
						ItemId: s.SectionTemplateId,
						Name: s.SectionName,
						Metadata: s.MetaData,
						Translation: s.Translation.map((t) => {
							return {
								Language: t.Language,
								Metadata: t.MetaData,
							};
						}),
					};
				}),
			});
		});
		return proccessedData;
	}

	processTemplateData(data) {
		this.pageDataForm = this._fb.group({
			Name: ['Global Page'],
			Language: this._fb.array([]) as FormArray,
		});
		this.languageList.forEach((language) => {
			let laguageControl = <FormArray>this.pageDataForm.controls['Language'];
			laguageControl.push(this.createLanguageFormControl(language, data));
		});

		this.getLanguageWisePageData(this.selectedLanguage);
		this._pageData.emit(this.selectedLanguageForm);
	}

	createLanguageFormControl(language, pageData) {
		let langForm = this._fb.group({
			LanguageName: [language],
			PageTemplates: this._fb.array([]) as FormArray,
		});

		if (pageData) {
			pageData.forEach((page) => {
				let control = <FormArray>langForm.controls['PageTemplates'];
				control.push(this.createPagesFormControl(page.MicrositePageName, language, page.SectionTemplates));
			});
		}

		return langForm;
	}

	createPagesFormControl(pageName, language, SectionData) {
		let form = this._fb.group({
			PageName: pageName,
			Sections: this._fb.array([]) as FormArray,
		});

		if (SectionData) {
			SectionData.forEach((section) => {
				if (section.ItemId != '19bdd6d4-d453-4674-bb33-f57f3459819') {
					let metaData;
					if (section.Translation) {
						let languageSpecifiData = section.Translation.find((langData) => langData.Language == language);
						if (languageSpecifiData) metaData = [...languageSpecifiData.Metadata];
						else metaData = [...section.Metadata];
					} else {
						metaData = [...section.Metadata];
					}
					let control = <FormArray>form.controls[`Sections`];
					control.push(this.createSectionFormControl(section, metaData));
				}
			});
		}

		return form;
	}

	createSectionFormControl(secTionInfo, metaData) {
		let form = this._fb.group({
			Category: [secTionInfo.Category],
			SectionId: [secTionInfo.ItemId],
			SectionName: [secTionInfo.Name],
			MetadataList: this._fb.array([]) as FormArray,
			OutletMetadata: [metaData && metaData.OutletMetadataList ? metaData.OutletMetadataList : null],
		});

		let control = <FormArray>form.controls['MetadataList'];
		let tempFormArray = this._fb.array([]) as FormArray;

		metaData.forEach((meta) => {
			tempFormArray = this._fb.array([]) as FormArray;
			if (meta && meta.EditableMetadata) {
				for (let [key, value] of Object.entries(meta.EditableMetadata)) {
					tempFormArray.push(this.createSectionMetaDataListControls(key, value));
				}
				if (meta && meta.ImageFiles && secTionInfo.ImageCount > 0) {
					tempFormArray.push(this.createSectionMetaDataListControls('ImageFiles', meta.ImageFiles));
				}
			}
			control.push(tempFormArray);
		});
		return form;
	}

	createSectionMetaDataListControls(key, value) {
		return this._fb.group({
			PropertyKey: [key],
			PropertyValue: [value],
			PropertyName: [key],
		});
	}

	getLanguageWisePageData(language) {
		this.pageDataForm.controls['Language']['controls'].find((lang) => {
			if (lang.value.LanguageName == language) {
				this.selectedLanguageForm = lang.controls.PageTemplates;
			}
		});
	}
}
