<div class="nutrition-view-modal">
	<div class="modal">
		<div fxLayout="row" fxLayoutAlign="space-between center" class="title">
			<h5>{{ foodItemTitle }}</h5>
			<button mat-dialog-close>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>

	<div class="tabGroup w-100-p">
		<mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)">
			<mat-tab *ngIf="data?.isContainAllergin" label="{{ 'ALLERGENS' | translate | uppercase }}"></mat-tab>
			<mat-tab
				*ngIf="data?.isContainNutrition"
				label="{{ 'NUTRITIONAL_VALUES' | translate | uppercase }}"
			></mat-tab>
			<mat-tab *ngIf="data?.isContainAdditives" label="{{ 'ADDITIVES' | translate | uppercase }}"></mat-tab>
		</mat-tab-group>
	</div>

	<div class="tabs">
		<div *ngIf="type == 'allergic'" class="allergic-content">
			<div class="title">
				<h1>{{ 'ALLERGENS' | translate }}</h1>
			</div>
			<ul class="parent-item">
				<li *ngFor="let allergic of nutritionValueList">
					<div>
						<span>{{ (allergic?.Translations | getTranslation : currentLang)?.Detail ?? '-' }}</span>
						<span>{{ (allergic?.Translations | getTranslation : currentLang)?.Code ?? '-' }}</span>
					</div>
					<ng-container
						*ngIf="(allergic?.Translations | getTranslation : currentLang)?.ChildData as ChildData"
					>
						<ul *ngIf="ChildData.length" class="children-item">
							<li *ngFor="let childItem of ChildData">
								<div>
									<span>
										<i>{{ childItem?.Detail ?? '-' }}</i>
									</span>
									<span>
										<i>{{ childItem?.ChildCode ?? '-' }}</i></span
									>
								</div>
							</li>
						</ul>
					</ng-container>
				</li>
			</ul>
		</div>

		<div *ngIf="type == 'additive'" class="additive-content">
			<div class="title">
				<h1>{{ 'ADDITIVES' | translate }}</h1>
			</div>
			<ul class="parent-item">
				<li *ngFor="let additive of nutritionValueList">
					<div>
						<span>{{
							(additive?.Translations | getTranslation : currentLang)?.IngredientName ?? '-'
						}}</span>
						<span>{{ (additive?.Translations | getTranslation : currentLang)?.ReferenceCode ?? '-' }}</span>
					</div>
				</li>
			</ul>
		</div>

		<div *ngIf="type == 'nutrition'" class="nutrition-content">
			<div class="title">
				<h1>{{ 'NUTRITIONAL_VALUES' | translate }}</h1>
			</div>
			<ul class="parent-item">
				<li>
					<div>
						<span style="font-weight: bold">{{ 'NUTRITIONAL_VALUES_2' | translate }} </span>
						<span class="nutrition-points">
							<span style="font-weight: bold">{{ 'PER_100G' | translate }}</span>
						</span>
					</div>
				</li>
				<li *ngFor="let nutrition of nutritionValueList">
					<div>
						<span>{{ nutrition?.NutrientName ?? '-' }} </span>
						<span class="nutrition-points">
							<span>
								{{ nutrition?.NutrientPer100g ?? '-' }}
							</span>
						</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>
