import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-video-section',
	templateUrl: './video-section.component.html',
	styleUrls: ['./video-section.component.scss'],
})
export class VideoSectionComponent implements OnInit {
	@Input('form') form: any = null;
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('videoURL') videoURL: string = 'https://vimeo.com/804206503';
	@Input('thumbnail') thumbnail: string = '../../../assets/images/video.png';
	@Input('videoBodyText') videoBodyText: string =
		'<p>Weitere Informationen sind im <a href="https://www.sv-group.de/de/ueber-uns/publikationen/-dl-/files/svgroup/de/user_upload/Faktenblatt_SV-Deutschland-V2.pdf" target="__blank"> Nachhaltigkeitsbericht </a> und auf dieser <a href="https://www.sv-group.de/de/verantwortung/nachhaltigkeit" target="__blank"> Website </a> enthalten.</p>';

	videoUrlForm: any = null;
	videoBodyTextForm: any = null;

	constructor(
		private appSectionTemplateService: AppSectionTemplateService,
		@Inject(DOCUMENT) private _document: Document
	) {}
	ngOnInit(): void {
		if (this.form !== null) {
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value === 'VIDEO_URL') {
					this.videoUrlForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'VIDEO_BODY_TEXT') {
					this.videoBodyTextForm = item.controls;
				}
			});
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'card-template');
		}

		if (this.videoUrlForm === null)
			this.videoUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.videoURL).controls;

		if (this.videoBodyTextForm === null)
			this.videoBodyTextForm = this.appSectionTemplateService.createPropertyFormGroup(
				this.videoBodyText
			).controls;

		this.setVideoURL();
	}

	onLinkToClick(url) {
		let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

		if (!/^http[s]?:\/\//.test(url)) {
			url = 'http://' + url.replaceAll(htmlRegexG, '');
		}
		const link = this._document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.click();
		link.remove();
	}

	setVideoURL() {
		this.processVideoURL();

		let srcdoc = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${this.videoUrlForm.PropertyValue.value}><img src=${this.thumbnail}></a>`;

		document.getElementById('myframe').setAttribute('src', this.videoUrlForm.PropertyValue.value);
		document.getElementById('myframe').setAttribute('srcdoc', srcdoc);
	}

	processVideoURL(): void {
		//We always need embed youtube/vimeo URL.
		//Process youtube url: 'https://www.youtube.com/embed/' + video ID.
		//Process vimeo url:  'https://player.vimeo.com/video/' + video ID.

		let isYoutubeUrl = this.videoUrlForm.PropertyValue.value.includes('youtube');

		if (isYoutubeUrl) {
			let id = this.videoUrlForm.PropertyValue.value.split('?v=')[1];
			this.videoUrlForm.PropertyValue.value = 'https://www.youtube.com/embed/' + id;
		} else {
			let vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
			let parsed = this.videoUrlForm.PropertyValue.value.match(vimeoRegex);
			this.videoUrlForm.PropertyValue.value = 'https://player.vimeo.com/video/' + parsed[1];
		}
	}
}
