import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-menu-plan',
	templateUrl: './menu-plan.component.html',
	styleUrls: ['./menu-plan.component.scss'],
})
export class MenuPlanComponent implements OnInit {
	@Input('form') form: any = null;
	@Input('isMobileView') isMobileView: boolean = false;
	menuPlans: any[] = [
		{
			id: 'menuPlan-1',
			week: '1',
			downloadLink: '../../../../assets/static-files/week20.pdf',
			isClicked: false,
			isExist: false,
		},
		{
			id: 'menuPlan-2',
			week: '2',
			downloadLink: '../../../../assets/static-files/week21.pdf',
			isClicked: false,
			isExist: false,
		},
	];
	constructor(@Inject(DOCUMENT) private _document: Document, private _router: Router) {}

	ngOnInit(): void {
		this.calculateWeekNumber();
		if (this.form != null) {
			if (this.form?.OutletMetadata?.value?.CurrentWeekMenuUrl) {
				this.menuPlans[0].downloadLink = this.form?.OutletMetadata?.value?.CurrentWeekMenuUrl;
				this.menuPlans[0].isExist = true;
			}

			if (this.form?.OutletMetadata?.value?.NextWeekMenuUrl) {
				this.menuPlans[1].downloadLink = this.form?.OutletMetadata?.value?.NextWeekMenuUrl;
				this.menuPlans[1].isExist = true;
			}
		}

		if (this._router.url.includes('template-preview')) {
			this.menuPlans[0].isExist = true;
			this.menuPlans[1].isExist = true;
		}
	}

	calculateWeekNumber() {
		const now = new Date();
		const onejan = new Date(now.getFullYear(), 0, 1);
		const week = Math.ceil(((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7);
		const nextWeek = week + 1;
		this.menuPlans[0].week = 'W' + week;
		this.menuPlans[1].week = 'W' + nextWeek;
	}

	onClickPlanCard(cardId: string) {
		let clickedCardItem = this.menuPlans.find((item) => item.id === cardId);
		clickedCardItem.isClicked = !clickedCardItem.isClicked;
		let otherCardItem = this.menuPlans.filter((item) => item.id !== cardId)[0];
		otherCardItem.isClicked = otherCardItem.isClicked ? false : otherCardItem.isClicked;

		const link = this._document.createElement('a');
		link.target = '_blank';
		link.href = clickedCardItem.downloadLink;
		link.click();
		link.remove();
	}
}
