import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-skeleton-loader',
	templateUrl: './skeleton-loader.component.html',
	styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoader implements OnInit {
	@Input('isMobileView') isMobileView: boolean;
	constructor() {}
	ngOnInit(): void {}
}
