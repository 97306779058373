import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-outlet-manager',
	templateUrl: './outlet-manager.component.html',
	styleUrls: ['./outlet-manager.component.scss'],
})
export class OutletManagerComponent implements OnInit {
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('outletList') outletList: any[];
	@Input('outletManagerInfo') outletManagerInfo: any;
	@Input('selectedOutlet') selectedOutlet: any;
	@Input('selectedManagerInfo') selectedManagerInfo: any;
	@Input('form') form: any = null;
	@Input('sectionImage') sectionImage: any[] = [
		{
			FileId: '',
			FileUrl:
				'https://selisefalcon.blob.core.windows.net/public-ecap-falcon/dev/90fb8c61-f09e-494d-8f00-ff833a2e2d99/2b9f566f-caf0-4cda-a9cd-579fd965cdba/afb1ec9b-72be-4e9b-b882-d869cbb5ccb7/outletInfo.png',
		},
	];
	@Input('sectionBodyContent') sectionBodyContent: string = '';
	@Input('outletDescription') outletDescription: string =
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
	outletManagerData: any;
	selectedRestaurantIndex = 0;
	sectionBodyContentForm: any = null;
	imageForm: any = null;
	openingHours: any = null;
	categoryHours: any = null;
	isConferenceEquipmentAvailable: any = null;
	isGardenAvailable: any = null;
	isInnerCourtyardAvailable: any = null;
	isParkingAvailable: any = null;
	isTakeAwayAvailable: any = null;
	isTerraceAvailable: any = null;
	NumberOfSeatsInGarden: any = null;
	NumberOfSeatsInInnerCourtyard: any = null;
	NumberOfSeatsInTerrace: any = null;
	NumberOfSeatsInside: any = null;
	outletDescriptionForm: any = null;

	availableEquipmentList: any = [];

	outletHeadName: any = null;
	outletHeadEmail: any = null;
	outletHeadRole: any = null;

	constructor(
		private appSectionTemplateService: AppSectionTemplateService,
		private router: Router,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		if (this.form !== null) {
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value === 'SECTION_BODY_CONTENT') {
					this.sectionBodyContentForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'ImageFiles') {
					this.imageForm = item.controls;
				}
			});
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'restaurant-manager-info');
		}

		if (this.sectionBodyContentForm === null)
			this.sectionBodyContentForm = this.appSectionTemplateService.createPropertyFormGroup(
				this.sectionBodyContent
			).controls;

		if (this.imageForm === null)
			this.imageForm = this.appSectionTemplateService.createPropertyFormGroup(this.sectionImage).controls;

		this.processOutletData();
		this.prepareEquipmentList();
	}

	processOutletData(): void {
		this.outletHeadName = this.form?.OutletMetadata.value?.OutletManagerName;
		this.outletHeadEmail = this.form?.OutletMetadata.value?.OutletManagerEmail;
		this.outletHeadRole = 'Restaurant Manager';
		this.openingHours = this.form?.OutletMetadata.value?.OpeningHours;
		this.categoryHours = this.form?.OutletMetadata.value?.CategoryHours;
		this.isConferenceEquipmentAvailable = this.form?.OutletMetadata.value?.IsConferenceEquipmentAvailable || false;
		this.isGardenAvailable = this.form?.OutletMetadata.value?.IsGardenAvailable || false;
		this.isInnerCourtyardAvailable = this.form?.OutletMetadata.value?.IsInnerCourtyardAvailable || false;
		this.isParkingAvailable = this.form?.OutletMetadata.value?.IsParkingAvailable || false;
		this.isTakeAwayAvailable = this.form?.OutletMetadata.value?.IsTakeAwayAvailable || false;
		this.isTerraceAvailable = this.form?.OutletMetadata.value?.IsTerraceAvailable || false;
		this.NumberOfSeatsInGarden = this.form?.OutletMetadata.value?.NumberOfSeatsInGarden || 0;
		this.NumberOfSeatsInInnerCourtyard = this.form?.OutletMetadata.value?.NumberOfSeatsInInnerCourtyard || 0;
		this.NumberOfSeatsInTerrace = this.form?.OutletMetadata.value?.NumberOfSeatsInTerrace || 0;
		this.NumberOfSeatsInside = this.form?.OutletMetadata.value?.NumberOfSeatsInside || 0;

		if (this.router.url.includes('template-preview')) {
			this.outletHeadName = 'Restaurant Manager Name';
			this.outletHeadEmail = 'Restaurant Manager Email';
			this.outletHeadRole = 'Restaurant Manager';

			// TODO: Move this translation data in seperate model file
			if (this._translateService.currentLang == 'de') {
				this.openingHours = [
					{ WeekDay: 'Tag 1', IsOpen: true, OpeningTime: 'HH.MM', ClosingTime: 'HH.MM' },
					{ WeekDay: 'Tag 2', IsOpen: true, OpeningTime: 'HH.MM', ClosingTime: 'HH.MM' },
				];
			} else if (this._translateService.currentLang == 'en') {
				this.openingHours = [
					{ WeekDay: 'Day 1', IsOpen: true, OpeningTime: 'HH.MM', ClosingTime: 'HH.MM' },
					{ WeekDay: 'Day 2', IsOpen: true, OpeningTime: 'HH.MM', ClosingTime: 'HH.MM' },
				];
			}

			this.isConferenceEquipmentAvailable = true;
			this.isGardenAvailable = true;
			this.isInnerCourtyardAvailable = true;
			this.isParkingAvailable = true;
			this.isTakeAwayAvailable = true;
			this.isTerraceAvailable = true;
			this.NumberOfSeatsInGarden = 10;
			this.NumberOfSeatsInInnerCourtyard = 10;
			this.NumberOfSeatsInTerrace = 10;
			this.NumberOfSeatsInside = 10;
		}
	}

	prepareEquipmentList() {
		if (this.isConferenceEquipmentAvailable) this.availableEquipmentList.push('CONFERENCE_TECHNOLOGY_AVAILABLE');
		if (this.isGardenAvailable) this.availableEquipmentList.push('GARDEN_AVAILABLE');
		if (this.isInnerCourtyardAvailable) this.availableEquipmentList.push('INNER_COUNTRY_YARD_AVAILABLE');
		if (this.isParkingAvailable) this.availableEquipmentList.push('PARKING_AVAILABLE');
		if (this.isTakeAwayAvailable) this.availableEquipmentList.push('TAKE_AWAY_AVAILABLE');
		if (this.isTerraceAvailable) this.availableEquipmentList.push('TERRACE_AVAILABLE');
		if (this.isGardenAvailable && this.NumberOfSeatsInGarden > 0)
			this.availableEquipmentList.push(
				`${this.NumberOfSeatsInGarden}   ${this._translateService.instant('SEAT_IN_GARDEN')} `
			);

		if (this.isInnerCourtyardAvailable && this.NumberOfSeatsInInnerCourtyard > 0)
			this.availableEquipmentList.push(
				`${this.NumberOfSeatsInInnerCourtyard}  ${this._translateService.instant('SEAT_IN_COUNTRY_YEARD')} `
			);
		if (this.isTerraceAvailable && this.NumberOfSeatsInTerrace > 0)
			this.availableEquipmentList.push(
				`${this.NumberOfSeatsInTerrace}  ${this._translateService.instant('SEAT_IN_TERRACE')} `
			);
		if (this.NumberOfSeatsInside > 0)
			this.availableEquipmentList.push(
				`${this.NumberOfSeatsInside}  ${this._translateService.instant('SEAT_IN_INSIDE')} `
			);
	}
}
