<section
	id="wide-banner"
	class="section-padding"
	[class.mobile-display]="isMobileView"
	style="scroll-margin-top: 88px"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div class="container">
		<div class="main-title" [innerHTML]="mainTitleForm?.PropertyValue?.value"></div>
		<div class="sub-title" [innerHTML]="subTitleForm?.PropertyValue?.value"></div>
		<img
			src="{{ ImageFilesForm?.PropertyValue?.value[0]?.FileUrl }}"
			onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
			alt="image-3"
			class="image"
		/>
	</div>
</section>
