import { RestaurantMenu } from './interface/restaurant-menu.interface';

export const menu_data: RestaurantMenu[] = [
	{
		MenuId: 'eeff4097-3a2d-4811-a7ab-f886e2643df6',
		OutletId: '553a2d59-e754-4b99-9238-8625010048ca',
		Description: 'Description',
		DayOfWeek: 'Mittwoch',
		Date: '2023-04-25T18:00:00Z',
		ImageFileUrl: '',
		Translations: [
			{
				Language: 'de',
				MenuDescription: 'Description',
				DayOfWeek: 'Mittwoch',
				Offering_Line: 'STREET',
			},
			{
				Language: 'en',
				MenuDescription: 'Description',
				DayOfWeek: 'Wednesday',
				Offering_Line: 'STREET',
			},
		],
		Offering_Line: '',
		FoodItemList: [
			{
				FoodItem: {
					FoodItemTitle: 'Snack',
					FoodItemDescription: 'FISHERMANS CATCH Wrap mit Rauchlachs und Ei',
					Price: [
						{
							PriceType: 'Intern',
							Amount: '4,5',
							Currency: '€',
						},
					],
					NutritientValueList: [
						{
							NutrientName: 'Energie',
							NutrientPer100g: '550.0 kJ 140.2 kcal',
							NutrientPer200g: '1100 kJ 280.4 kcal',
						},
						{
							NutrientName: 'Fett',
							NutrientPer100g: '0.7 g',
							NutrientPer200g: '1.4 g',
						},
						{
							NutrientName: 'davon gesättigte Fettsäuren',
							NutrientPer100g: '0.2 g',
							NutrientPer200g: '0.4 g',
						},
						{
							NutrientName: 'Kohlenhydrate',
							NutrientPer100g: '30.0 g',
							NutrientPer200g: '60.0 g',
						},
						{
							NutrientName: 'davon Zucker',
							NutrientPer100g: '0.2 g',
							NutrientPer200g: '0.4 g',
						},
						{
							NutrientName: 'Eiweiss',
							NutrientPer100g: '2.8 g',
							NutrientPer200g: '5.6 g',
						},
						{
							NutrientName: 'Salz',
							NutrientPer100g: '2g',
							NutrientPer200g: '4g',
						},
					],
					AllergicInfoList: ['b462d976-9fd5-42fa-ae6a-cef25b6b6e97'],
					AdditiveInfoList: ['38049e79-26f8-4ac9-95d0-04c7abafa6cb', '72c96da1-9537-4bc2-a859-9cd88f9e6612'],
					FoodCategory: '',
					Origin: ['08e0a0b1-7cb9-41c2-9260-c6c1d5527011'],
					FoodItemImageFileUrl: '',
					Translations: [
						{
							Language: 'de',
							FoodItemDescription: 'FISHERMANS CATCH Wrap with smoked salmon and egg',
							FoodItemTitle: 'Snack',
							NutritientValueList: [
								{
									NutrientName: 'Energie',
									NutrientPer100g: '550.0 kJ 140.2 kcal',
									NutrientPer200g: '1100 kJ 280.4 kcal',
								},
								{
									NutrientName: 'Fett',
									NutrientPer100g: '0.7 g',
									NutrientPer200g: '1.4 g',
								},
								{
									NutrientName: 'davon gesättigte Fettsäuren',
									NutrientPer100g: '0.2 g',
									NutrientPer200g: '0.4 g',
								},
								{
									NutrientName: 'Kohlenhydrate',
									NutrientPer100g: '30.0 g',
									NutrientPer200g: '60.0 g',
								},
								{
									NutrientName: 'davon Zucker',
									NutrientPer100g: '0.2 g',
									NutrientPer200g: '0.4 g',
								},
								{
									NutrientName: 'Eiweiss',
									NutrientPer100g: '2.8 g',
									NutrientPer200g: '5.6 g',
								},
								{
									NutrientName: 'Salz',
									NutrientPer100g: '2g',
									NutrientPer200g: '4g',
								},
							],
						},
					],
				},
				AdditiveInfoDetails: [
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: '4385fe2a-bbd6-49f1-a6f8-ff51182505bf',
								IngredientName: 'mit Antioxidationsmitteln',
								ReferenceCode: '3',
							},
						],
						ItemId: '38049e79-26f8-4ac9-95d0-04c7abafa6cb',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:36.534Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: 'f85383c6-384b-475c-8eb4-d516037965c3',
								IngredientName: 'kann bei übermäßigem Verzehr abführend wirken',
								ReferenceCode: '12',
							},
						],
						ItemId: '72c96da1-9537-4bc2-a859-9cd88f9e6612',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:39.739Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
				],
				AllergicInfoDetails: [
					{
						Translations: [
							{
								Language: 'de',
								Detail: 'Sesamsamen',
								Code: 'K',
								ReferenceCode: 'K',
							},
						],
						ItemId: 'b462d976-9fd5-42fa-ae6a-cef25b6b6e97',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:33.495Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
				],
				OriginOfFoodDetails: [
					{
						Category: 'Herkunft Pute, Schweiz DE',
						ItemId: '08e0a0b1-7cb9-41c2-9260-c6c1d5527011',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:41.103Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
						Translations: [
							{
								Language: 'en',
								Category: 'Grilled Sausage',
							},
							{
								Language: 'de',
								Category: 'Bratwurst',
							},
						],
					},
					{
						Category: 'Herkunft Ei, Schweiz DE',
						ItemId: '08e0a0b1-7cb9-41c2-9260-c6c1d552701r',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:41.103Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
						Translations: [
							{
								Language: 'en',
								Category: 'Grilled Sausage',
							},
							{
								Language: 'de',
								Category: 'Bratwurst',
							},
						],
					},
					{
						Category: 'Herkunft Ei, Schweiz DE',
						ItemId: '08e0a0b1-7cb9-41c2-9260-c6c1d552701r',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:41.103Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
						Translations: [
							{
								Language: 'en',
								Category: 'Grilled Sausage',
							},
							{
								Language: 'de',
								Category: 'Bratwurst',
							},
						],
					},
					{
						Category: 'Herkunft Ei, Schweiz DE',
						ItemId: '08e0a0b1-7cb9-41c2-9260-c6c1d552701r',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:41.103Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
						Translations: [
							{
								Language: 'en',
								Category: 'Grilled Sausage',
							},
							{
								Language: 'de',
								Category: 'Bratwurst',
							},
						],
					},
				],
			},
		],
	},
	{
		MenuId: 'eeff4097-3a2d-4811-a7ab-f886e2643df7',
		OutletId: '553a2d59-e754-4b99-9238-8625010048ca',
		Description: 'Description',
		DayOfWeek: 'Donnerstag ',
		Date: '2023-04-26T18:00:00Z',
		ImageFileUrl: '',
		Translations: [
			{
				Language: 'de',
				MenuDescription: 'Description',
				DayOfWeek: 'Donnerstag',
				Offering_Line: 'STREET',
			},
			{
				Language: 'en',
				MenuDescription: 'Description',
				DayOfWeek: 'Thursday',
				Offering_Line: 'STREET',
			},
		],
		Offering_Line: 'STREET',
		FoodItemList: [
			{
				FoodItem: {
					FoodItemTitle: 'Küche aus Deutschland mit vielen leckeren Geschmacksrichtungen',
					FoodItemDescription: 'HUBERTUS Hirschpfeffer mit Rosenkohlgemüse, Preiselbeeren und Spätzli ',
					Price: [
						{
							PriceType: 'Intern',
							Amount: '6,5',
							Currency: '€',
						},
					],
					NutritientValueList: [
						{
							NutrientName: 'Energie',
							NutrientPer100g: '550.0 kJ 140.2 kcal',
							NutrientPer200g: '1100 kJ 280.4 kcal',
						},
						{
							NutrientName: 'Fett',
							NutrientPer100g: '0.7 g',
							NutrientPer200g: '1.4 g',
						},
						{
							NutrientName: 'davon gesättigte Fettsäuren',
							NutrientPer100g: '0.2 g',
							NutrientPer200g: '0.4 g',
						},
						{
							NutrientName: 'Kohlenhydrate',
							NutrientPer100g: '30.0 g',
							NutrientPer200g: '60.0 g',
						},
						{
							NutrientName: 'davon Zucker',
							NutrientPer100g: '0.2 g',
							NutrientPer200g: '0.4 g',
						},
						{
							NutrientName: 'Eiweiss',
							NutrientPer100g: '2.8 g',
							NutrientPer200g: '5.6 g',
						},
						{
							NutrientName: 'Salz',
							NutrientPer100g: '2g',
							NutrientPer200g: '4g',
						},
					],
					AllergicInfoList: [
						'aa23837f-ab2e-4c49-8198-b7f6535ee9a0',
						'87faa026-bde1-4abd-86b7-f395fa69841a',
						'f5c613ec-2fd7-471f-80eb-1baea7815401',
						'0ecc9cd1-8c1c-4dcc-a8c1-36494372eaae',
					],
					AdditiveInfoList: [
						'4385fe2a-bbd6-49f1-a6f8-ff51182505bf',
						'f85383c6-384b-475c-8eb4-d516037965c3',
						'ff3d2eb2-d3d9-44d1-9eb9-c9a5bb15f3ca',
					],
					FoodCategory: 'vegetarisch',
					Origin: ['08e0a0b1-7cb9-41c2-9260-c6c1d5527011'],
					FoodItemImageFileUrl: '',
					Translations: [
						{
							Language: 'de',
							FoodItemDescription:
								'HUBERTUS venison pepper with Brussels sprouts, cranberries and spaetzli',
							FoodItemTitle: 'Cuisine from Germany with many delicious flavors',
							NutritientValueList: [
								{
									NutrientName: 'Energie',
									NutrientPer100g: '550.0 kJ 140.2 kcal',
									NutrientPer200g: '1100 kJ 280.4 kcal',
								},
								{
									NutrientName: 'Fett',
									NutrientPer100g: '0.7 g',
									NutrientPer200g: '1.4 g',
								},
								{
									NutrientName: 'davon gesättigte Fettsäuren',
									NutrientPer100g: '0.2 g',
									NutrientPer200g: '0.4 g',
								},
								{
									NutrientName: 'Kohlenhydrate',
									NutrientPer100g: '30.0 g',
									NutrientPer200g: '60.0 g',
								},
								{
									NutrientName: 'davon Zucker',
									NutrientPer100g: '0.2 g',
									NutrientPer200g: '0.4 g',
								},
								{
									NutrientName: 'Eiweiss',
									NutrientPer100g: '2.8 g',
									NutrientPer200g: '5.6 g',
								},
								{
									NutrientName: 'Salz',
									NutrientPer100g: '2g',
									NutrientPer200g: '4g',
								},
							],
						},
					],
				},
				AdditiveInfoDetails: [
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: '80604575-1950-4087-b333-7e6fb57105b7',
								IngredientName: 'mit Geschmacksverstärkern',
								ReferenceCode: '4',
							},
						],
						ItemId: '4385fe2a-bbd6-49f1-a6f8-ff51182505bf',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:36.935Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: null,
								IngredientName: 'coffeinhaltig',
								ReferenceCode: '15',
							},
						],
						ItemId: 'f85383c6-384b-475c-8eb4-d516037965c3',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:40.136Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: '72c96da1-9537-4bc2-a859-9cd88f9e6612',
								IngredientName: 'enthält eine Phenylalaninquelle',
								ReferenceCode: '11',
							},
						],
						ItemId: 'ff3d2eb2-d3d9-44d1-9eb9-c9a5bb15f3ca',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:39.333Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
				],
				AllergicInfoDetails: [
					{
						Translations: [
							{
								Language: 'de',
								Detail: 'Sellerie',
								Code: 'I',
								ReferenceCode: 'I',
							},
						],
						ItemId: '0ecc9cd1-8c1c-4dcc-a8c1-36494372eaae',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:32.702Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								Detail: 'Fische',
								Code: 'D',
								ReferenceCode: 'D',
							},
						],
						ItemId: '87faa026-bde1-4abd-86b7-f395fa69841a',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:30.708Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								Detail: 'Krebstiere',
								Code: 'B',
								ReferenceCode: 'B',
							},
						],
						ItemId: 'aa23837f-ab2e-4c49-8198-b7f6535ee9a0',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:29.914Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								Detail: 'Sojabohnen',
								Code: 'F',
								ReferenceCode: 'F',
							},
						],
						ItemId: 'f5c613ec-2fd7-471f-80eb-1baea7815401',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:31.51Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
				],
				OriginOfFoodDetails: [
					{
						Category: 'Herkunft Pute: Schweiz',
						ItemId: '08e0a0b1-7cb9-41c2-9260-c6c1d5527011',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:41.103Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
						Translations: [
							{
								Language: 'en',
								Category: ' Breaded Cutlet',
							},
							{
								Language: 'de',
								Category: 'Schnitzel',
							},
						],
					},
				],
			},
		],
	},
	{
		MenuId: 'eeff4097-3a2d-4811-a7ab-f886e2643df8',
		OutletId: '553a2d59-e754-4b99-9238-8625010048ca',
		Description: 'Description',
		DayOfWeek: 'Freitag ',
		Date: '2023-04-27T18:00:00Z',
		ImageFileUrl: '',
		Translations: [
			{
				Language: 'de',
				MenuDescription: 'Description',
				DayOfWeek: 'Freitag',
				Offering_Line: 'STREET',
			},
			{
				Language: 'en',
				MenuDescription: 'Description',
				DayOfWeek: 'Friday',
				Offering_Line: 'STREET',
			},
		],
		Offering_Line: '',
		FoodItemList: [
			{
				FoodItem: {
					FoodItemTitle: 'Veggie/Vegan',
					FoodItemDescription: 'SEED Vegane Hackbällchen in Pilzrahmsauce und Risi-Bisi',
					Price: [
						{
							PriceType: 'Intern',
							Amount: '5,5',
							Currency: '€',
						},
					],
					NutritientValueList: [
						{
							NutrientName: 'Energie',
							NutrientPer100g: '550.0 kJ 140.2 kcal',
							NutrientPer200g: '1100 kJ 280.4 kcal',
						},
						{
							NutrientName: 'Fett',
							NutrientPer100g: '0.7 g',
							NutrientPer200g: '1.4 g',
						},
						{
							NutrientName: 'davon gesättigte Fettsäuren',
							NutrientPer100g: '0.2 g',
							NutrientPer200g: '0.4 g',
						},
						{
							NutrientName: 'Kohlenhydrate',
							NutrientPer100g: '30.0 g',
							NutrientPer200g: '60.0 g',
						},
						{
							NutrientName: 'davon Zucker',
							NutrientPer100g: '0.2 g',
							NutrientPer200g: '0.4 g',
						},
						{
							NutrientName: 'Eiweiss',
							NutrientPer100g: '2.8 g',
							NutrientPer200g: '5.6 g',
						},
						{
							NutrientName: 'Salz',
							NutrientPer100g: '2g',
							NutrientPer200g: '4g',
						},
					],
					AllergicInfoList: ['92602a08-424b-40ce-9437-f9e2b9335dc1'],
					AdditiveInfoList: ['38049e79-26f8-4ac9-95d0-04c7abafa6cb', '72c96da1-9537-4bc2-a859-9cd88f9e6612'],
					FoodCategory: '',
					Origin: ['08e0a0b1-7cb9-41c2-9260-c6c1d5527011'],
					FoodItemImageFileUrl: '',
					Translations: [
						{
							Language: 'de',
							FoodItemDescription: 'SEED Vegan meatballs in mushroom cream sauce and risi bisi',
							FoodItemTitle: 'Vegetarian or vegan',
							NutritientValueList: [
								{
									NutrientName: 'Energie',
									NutrientPer100g: '550.0 kJ 140.2 kcal',
									NutrientPer200g: '1100 kJ 280.4 kcal',
								},
								{
									NutrientName: 'Fett',
									NutrientPer100g: '0.7 g',
									NutrientPer200g: '1.4 g',
								},
								{
									NutrientName: 'davon gesättigte Fettsäuren',
									NutrientPer100g: '0.2 g',
									NutrientPer200g: '0.4 g',
								},
								{
									NutrientName: 'Kohlenhydrate',
									NutrientPer100g: '30.0 g',
									NutrientPer200g: '60.0 g',
								},
								{
									NutrientName: 'davon Zucker',
									NutrientPer100g: '0.2 g',
									NutrientPer200g: '0.4 g',
								},
								{
									NutrientName: 'Eiweiss',
									NutrientPer100g: '2.8 g',
									NutrientPer200g: '5.6 g',
								},
								{
									NutrientName: 'Salz',
									NutrientPer100g: '2g',
									NutrientPer200g: '4g',
								},
							],
						},
					],
				},
				AdditiveInfoDetails: [
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: '4385fe2a-bbd6-49f1-a6f8-ff51182505bf',
								IngredientName: 'mit Antioxidationsmitteln',
								ReferenceCode: '3',
							},
						],
						ItemId: '38049e79-26f8-4ac9-95d0-04c7abafa6cb',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:36.534Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
					{
						Translations: [
							{
								Language: 'de',
								AdditiveInfoId: 'f85383c6-384b-475c-8eb4-d516037965c3',
								IngredientName: 'kann bei übermäßigem Verzehr abführend wirken',
								ReferenceCode: '12',
							},
						],
						ItemId: '72c96da1-9537-4bc2-a859-9cd88f9e6612',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:39.739Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
				],
				AllergicInfoDetails: [
					{
						Translations: [
							{
								Language: 'de',
								Detail: 'Schalenfrüchte',
								Code: 'H',
								ReferenceCode: 'H',
							},
						],
						ItemId: '92602a08-424b-40ce-9437-f9e2b9335dc1',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:32.304Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
					},
				],
				OriginOfFoodDetails: [
					{
						Category: 'Herkunft Hähnchen: Deutschland',
						ItemId: '08e0a0b1-7cb9-41c2-9260-c6c1d5527011',
						MetaTags: null,
						CreatedBy: '',
						CreateDate: '2023-03-22T10:44:41.103Z',
						LastUpdatedBy: null,
						LastUpdateDate: null,
						IsActive: false,
						IsDeleted: false,
						Language: null,
						Translations: [
							{
								Language: 'en',
								Category: 'Grilled Sausage',
							},
							{
								Language: 'de',
								Category: 'Bratwurst',
							},
						],
					},
				],
			},
		],
	},
];
