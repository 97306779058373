import { Component, Input, OnInit } from "@angular/core";
import { AppSectionTemplateService } from "../../services/section-template.service";

@Component({
  selector: "app-food-origin-section",
  templateUrl: "./food-origin-section.component.html",
  styleUrls: ["./food-origin-section.component.scss"],
})
export class FoodOriginSection implements OnInit {
  @Input("mainTitle1") mainTitle1: string = "Fleisch mit Qualität";
  @Input("mainTitle2") mainTitle2: string = "Nachhaltiger Fisch";
  @Input("mainTitle3") mainTitle3: string = "Schweizer Wiesenmilch";
  @Input("subTitle1") subTitle1: string =
    "Kalb, Rind, Schwein und Huhn kommen aus der Schweiz. Wir vertrauen auf Produzenten, die ihre Tiere nach besonders hohen Tierschutzstandards halten.";
  @Input("subTitle2") subTitle2: string =
    "Nachhaltiger Fisch auf den Tisch! Ihr Restaurant ist MSC- und ASC-zertifiziert*. MSC steht für die langfristige Erhaltung der Fischbestände und ASC bedeutet...";
  @Input("subTitle3") subTitle3: string =
    "Der Milchschaum auf Ihrem Cappuccino ist etwas ganz Besonderes. Die Milch stammt von Kühen, die nach dem IP-SUISSE-Standard gehalten werden.";
  @Input("imageFiles") imageFiles: any[] = [
    {
      FileId: "ed0e1256-5c83-41e3-82ea-d9534e2b9772",
      FileUrl: "../../../../assets/static-image/food-card-1.png",
    },
    {
      FileId: "ed0e1256-5c83-41e3-82ea-d9534e2b9773",
      FileUrl: "../../../../assets/static-image/food-card-3.png",
    },
    {
      FileId: "ed0e1256-5c83-41e3-82ea-d9534e2b9774",
      FileUrl: "../../../../assets/static-image/food-card-2.png",
    },
  ];
  @Input("form") form: any = null;
  @Input("isMobileView") isMobileView: boolean = false;
  constructor(private appSectionTemplateService: AppSectionTemplateService) {}

  mainTitle1Form: any = null;
  mainTitle2Form: any = null;
  mainTitle3Form: any = null;
  subTitle1Form: any = null;
  subTitle2Form: any = null;
  subTitle3Form: any = null;
  imageFiles1Form: any = null;
  imageFiles2Form: any = null;
  imageFiles3Form: any = null;

  ngOnInit(): void {
    if (this.form !== null) {
      this.form.MetadataList.controls[0].controls.forEach((item) => {
        if (item.controls.PropertyKey.value === "MAIN_TITLE") {
          this.mainTitle1Form = item.controls;
        } else if (item.controls.PropertyKey.value === "SUB_TITLE") {
          this.subTitle1Form = item.controls;
        } else if (item.controls.PropertyKey.value === "ImageFiles") {
          this.imageFiles1Form = item.controls;
        }
      });
      this.form.MetadataList.controls[1].controls.forEach((item) => {
        if (item.controls.PropertyKey.value === "MAIN_TITLE") {
          this.mainTitle2Form = item.controls;
        } else if (item.controls.PropertyKey.value === "SUB_TITLE") {
          this.subTitle2Form = item.controls;
        } else if (item.controls.PropertyKey.value === "ImageFiles") {
          this.imageFiles2Form = item.controls;
        }
      });

      this.form.MetadataList.controls[2].controls.forEach((item) => {
        if (item.controls.PropertyKey.value === "MAIN_TITLE") {
          this.mainTitle3Form = item.controls;
        } else if (item.controls.PropertyKey.value === "SUB_TITLE") {
          this.subTitle3Form = item.controls;
        } else if (item.controls.PropertyKey.value === "ImageFiles") {
          this.imageFiles3Form = item.controls;
        }
      });
      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form.IsOnEdit,
        "food-origin"
      );
    }

    if (this.mainTitle1Form === null) {
      this.mainTitle1Form =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.mainTitle1
        ).controls;
    }

    if (this.mainTitle2Form === null) {
      this.mainTitle2Form =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.mainTitle2
        ).controls;
    }

    if (this.mainTitle3Form === null) {
      this.mainTitle3Form =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.mainTitle3
        ).controls;
    }

    if (this.subTitle1Form === null) {
      this.subTitle1Form =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.subTitle1
        ).controls;
    }
    if (this.subTitle2Form === null) {
      this.subTitle2Form =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.subTitle2
        ).controls;
    }
    if (this.subTitle3Form === null) {
      this.subTitle3Form =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.subTitle3
        ).controls;
    }
    if (this.imageFiles1Form === null) {
      this.imageFiles1Form =
        this.appSectionTemplateService.createPropertyFormGroup([
          this.imageFiles[0],
        ]).controls;
    }
    if (this.imageFiles2Form === null) {
      this.imageFiles2Form =
        this.appSectionTemplateService.createPropertyFormGroup([
          this.imageFiles[1],
        ]).controls;
    }
    if (this.imageFiles3Form === null) {
      this.imageFiles3Form =
        this.appSectionTemplateService.createPropertyFormGroup([
          this.imageFiles[2],
        ]).controls;
    }
  }
}
