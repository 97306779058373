import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppSnackbarComponent } from './components/app-snackbar/app-snackbar.component';
import { AppStarRatingComponent } from './components/app-star-rating/app-star-rating.component';
import { SvProgressSpinnerComponent } from './components/sv-progress-spinner/sv-progress-spinner.component';
import { MaterialModule } from './material.module';
import { SvBusinessService } from './services/sv-business.service';

@NgModule({
	imports: [CommonModule, MaterialModule, TranslateModule.forRoot()],
	declarations: [SvProgressSpinnerComponent, AppSnackbarComponent, AppStarRatingComponent],
	exports: [CommonModule, TranslateModule, SvProgressSpinnerComponent, AppStarRatingComponent],
	providers: [SvBusinessService],
})
export class SharedModule {}
