import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, map, of } from 'rxjs';
import { ApiResponse } from '../../../../src/app/shared/Interfaces/api-response.interface';
import { foodItemType } from '../../models/microsite-frontend.model';
import { AppSectionTemplateService } from '../../services/section-template.service';
import { FoodItem, RestaurantMenu } from './interface/restaurant-menu.interface';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { menu_data } from './static-data';
@Component({
	selector: 'app-restaurant-menu-section',
	templateUrl: './restaurant-menu-section.component.html',
	styleUrls: ['./restaurant-menu-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantMenuSectionComponent implements OnInit, OnDestroy {
	@Input('isMobileView') isMobileView: boolean;
	@Input('form') form: any = null;
	@Input('selectedOutlet') selectedOutlet: any = null;
	isLoading: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<any>();
	outletId: string;
	menuData$: Observable<RestaurantMenu[]>;
	menuData: RestaurantMenu[] = [];
	currentLang: string = 'de';
	contentLanguge: string = 'de';

	constructor(
		private _router: Router,
		public dialog: MatDialog,
		public translate: TranslateService,
		private sharedTemplateService: AppSectionTemplateService
	) {
		this.currentLang = this.translate.currentLang;
	}

	ngOnInit(): void {
		if (this.form && this.form.Language) this.currentLang = this.form.Language.value;
		if (this.selectedOutlet != null) {
			this.outletId = this.selectedOutlet.value.Id;
		}
		if (!this._router.url.includes('template-preview')) {
			this.menuData$ = this.getMenuData();
			return;
		}

		this.menuData$ = of(menu_data);
		this.menuData = menu_data;
	}
	// ### unused
	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
	capitalizedLetter(word: string): string {
		return (
			word.split('')[0].toUpperCase() +
			word
				.split('')
				.filter((char, i) => i !== 0)
				.join('')
		);
	}

	openDialog(title: string): void {
		let category = foodItemType.find((item) => item.typeName === title.toLocaleLowerCase());
		this.dialog.open(ModalDialogComponent, {
			width: '439px',
			data: {
				title: this.capitalizedLetter(category.typeName),
				description: category.description,
			},
		});
	}

	getMenuData(): Observable<ApiResponse<RestaurantMenu>['Result']['Records']> {
		return this.sharedTemplateService.getOutletMenuDetails(this.outletId).pipe(
			map((res: ApiResponse<RestaurantMenu>) => {
				if (res && res.IsSuccess) {
					this.menuData = res.Result.Records;
					return res.Result.Records;
				}
				this.isLoading = false;
				return [];
			})
		);
	}

	getFoodItemTypeIcon(type: string) {
		return foodItemType.find((item) => item.typeName === type.toLowerCase()).icon;
	}

	trackByFn(index: number, item: FoodItem) {
		return item.FoodItem.FoodItemTitle + index;
	}
}
