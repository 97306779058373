<div class="loader">
	<div class="green-header">
		<div class="icon"></div>
		<div class="date"></div>
	</div>
	<div class="card">
		<div class="food-name"></div>
		<div class="wrapper">
			<div class="description">
				<div class="description1"></div>
				<div class="description2"></div>
			</div>
			<div class="price"></div>
			<div class="price"></div>
		</div>
		<div class="footer">
			<span class="footer-child"></span>
			<span class="footer-child"></span>
		</div>
	</div>
</div>
