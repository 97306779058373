<!-- <ng-container *ngIf="menuData$ | async"></ng-container> -->
<!-- <section class="restaurant-menu-section section-padding" [class.smaller-display]="isMobileView"> -->
<section
	class="restaurant-menu"
	[ngClass]="{ 'restaurant-menu-mobile': isMobileView, 'restaurant-menu': !isMobileView }"
>
	<div class="restaurant-menu__name">
		<h6>{{ 'MENU' | translate }}</h6>
		<h3>{{ selectedOutlet?.value.Name || 'OUTLET_NAME' | translate }}</h3>
	</div>

	<div class="restaurant-menu__accordion">
		<ng-container [ngTemplateOutlet]="(menuData$ | async) ? mainContent : loader"> </ng-container>
	</div>
</section>

<ng-template #mainContent>
	<mat-accordion *ngFor="let openingSchedule of menuData; first as isFirst">
		<mat-expansion-panel [expanded]="isFirst">
			<mat-expansion-panel-header>
				<div fxLayoutAlign="center">
					<mat-icon fxLayoutAlign="center center">event</mat-icon>
					<h5>
						{{
							(openingSchedule.Translations | getTranslation : currentLang)?.DayOfWeek ??
								openingSchedule.DayOfWeek
						}}
						{{ (openingSchedule?.Date | date : 'dd.MM.YYYY') || 'DD.MM.YYYY' }}
					</h5>
				</div>
			</mat-expansion-panel-header>

			<mat-panel-description>
				<div class="details-grid">
					<app-restaurant-menu-food-item
						*ngFor="let menuItem of openingSchedule.FoodItemList; trackBy: trackByFn"
						[menuItem]="menuItem"
						[isMobileView]="isMobileView"
						[currentLang]="currentLang"
					>
					</app-restaurant-menu-food-item>
				</div>
			</mat-panel-description>
		</mat-expansion-panel>
	</mat-accordion>
</ng-template>
<ng-template #loader>
	<app-skeleton-loader></app-skeleton-loader>
</ng-template>
