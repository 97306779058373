import { Component, Input, OnInit } from "@angular/core";
import { AppSectionTemplateService } from "../../services/section-template.service";

@Component({
  selector: "app-left-image-card-section",
  templateUrl: "./left-image-card-section.component.html",
  styleUrls: ["./left-image-card-section.component.scss"],
})
export class LeftImageCardSectionComponent implements OnInit {
  @Input("imageFiles") imageFiles: any[] = [
    {
      FileUrl: "../../../../assets/static-image/card-image-1.svg",
    },
  ];
  @Input("mainTitle") mainTitle: string = "Gemüse und Früchte aus Ihrer Region";
  @Input("subTitle") subTitle: string =
    "Wir kaufen Gemüse und Obst, wenn es in der Schweiz oder im benachbarten Ausland gerade Saison hat. Während der Schweizer Saison (von April bis Oktober) beziehen wir Tomaten, Gurken, Auberginen und Chilischoten aus klimafreundlichen Gewächshäusern, die in den Übergangsmonaten mit Abwärme oder Erdwärme beheizt werden. Wir bevorzugen Produkte mit den Labels SUISSE GARANTIE und IP-SUISSE. So verwenden wir zum Beispiel nur Karotten aus IP-SUISSE-Anbau";
  @Input("form") form: any = null;
  @Input("isMobileView") isMobileView: boolean = false;

  ImageFilesForm: any = null;
  mainTitleForm: any = null;
  subTitleForm: any = null;

  constructor(private appSectionTemplateService: AppSectionTemplateService) {}
  ngOnInit(): void {
    if (this.form !== null) {
      this.form.MetadataList.controls[0].controls.forEach((item) => {
        if (item.controls.PropertyKey.value === "ImageFiles") {
          this.ImageFilesForm = item.controls;
        } else if (item.controls.PropertyKey.value === "MAIN_TITLE") {
          this.mainTitleForm = item.controls;
        } else if (item.controls.PropertyKey.value === "SUB_TITLE") {
          this.subTitleForm = item.controls;
        }
      });
      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form?.IsOnEdit,
        "card-template"
      );
    }

    if (this.ImageFilesForm === null) {
      this.ImageFilesForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.imageFiles
        ).controls;
    }

    if (this.mainTitleForm === null) {
      this.mainTitleForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.mainTitle
        ).controls;
    }

    if (this.subTitleForm === null)
      this.subTitleForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.subTitle
        ).controls;
  }
}
