<div
	id="card-template"
	class="section-padding"
	style="scroll-margin-top: 88px; background: #e9e9e9"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div class="container">
		<div class="video-container">
			<iframe id="myframe" class="responsive-iframe"> </iframe>
		</div>
		<div
			fxLayout
			fxLayoutAlign.gt-sm="center center"
			class="video-body"
			style="color: #262626"
			[innerHtml]="this.videoBodyTextForm?.PropertyValue?.value"
		></div>
	</div>
</div>
