<div
	id="card-template"
	class="section-padding"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div class="card" fxLayout="row" fxLayout.lt-md="column" ngClass.sm="tablet-display" ngClass.xs="mobile-display">
		<div class="image" fxFlex.lt-md="100%" fxFlex="50%">
			<img
				height="100%"
				width="100%"
				src="{{ ImageFilesForm?.PropertyValue?.value[0]?.FileUrl }}"
				onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
				alt="card-img"
			/>
		</div>
		<div class="details" fxFlex.lt-md="100%" fxFlex="50%">
			<div class="title" [innerHtml]="mainTitleForm.PropertyValue.value"></div>
			<div class="body" [innerHtml]="bodyTextForm.PropertyValue.value"></div>

			<button
				*ngIf="buttonTextForm?.PropertyValue?.value?.length >= 1"
				mat-flat-button
				class="button"
				(click)="onLinkToClick(linkToButtonForm?.PropertyValue?.value || buttonURL)"
				fxLayout="row"
				fxLayoutAlign="center center"
			>
				<p>{{ buttonTextForm?.PropertyValue?.value || buttonText }}</p>
				<mat-icon class="material-symbols-outlined"> chevron_right </mat-icon>
			</button>
		</div>
	</div>
</div>
