<div
	id="top-banner"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="form?.IsOnEdit?.value"
	style="scroll-margin-top: 88px"
	[class.top-margin]="isPreview"
	[class.remove]="isLandingPage"
>
	<div class="banner-container">
		<div style="scroll-margin-top: 88px" class="mySlides">
			<div class="slide">
				<img src="{{ imageLinksForm?.PropertyValue?.value[0]?.FileUrl }}" />
			</div>
		</div>
		<div class="shadow-wrap"></div>
		<div class="content-wrapper content-padding" fxLayout="column" fxLayoutAlign="center start">
			<p class="main-title" [innerHTML]="mainTitleForm?.PropertyValue.value"></p>
			<p class="sub-title mt-8" [innerHTML]="subTitleForm?.PropertyValue.value"></p>
		</div>
	</div>
</div>
