import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { Router } from "@angular/router";
import { fromEvent, withLatestFrom, zipWith } from "rxjs";
import { AppSectionTemplateService } from "../../services/section-template.service";

@Component({
  selector: "app-top-banner-carousel",
  templateUrl: "./top-banner-carousel.component.html",
  styleUrls: ["./top-banner-carousel.component.scss"],
})
export class TopBannerCarouselComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input("isMobileView") isMobileView: boolean = false;
  @Input("mainTitle") mainTitle: any = "Here is the website";
  @Input("subTitle") subTitle: any =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus habitasse lacinia et, mauris, morbi.";
  @Input("buttonText") buttonText: any = "";
  @Input("buttonLinkUrl") buttonLinkUrl: any = "www.google.com";
  @Input("imageLinks") imageLinks: any[] = [
    {
      FileUrl:
        "https://www.sv-group.ch/files/_processed_/f/7/csm_Desktop_DSC4010-1920x600px_850539e648.jpg",
    },
    {
      FileUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
    },
    {
      FileUrl:
        "https://cdn.pixabay.com/photo/2015/06/19/21/24/avenue-815297_960_720.jpg",
    },
  ];
  @Input("form") form: any = null;
  @Input("showButton") showButton: boolean = false;
  @Input("carouselHeight") carouselHeight: number = 580;
  @Output("onButtonClick") onButtonClick: EventEmitter<any> =
    new EventEmitter();

  mainTitleForm = [];
  subTitleForm = [];
  buttonTextForm = [];
  imageLinksForm = [];
  linkToButtonForm = [];
  slideIndex = 0;
  sectionHeight = "0px";
  timeOutIDs: any[] = [];
  isPlatformBrowser = false;
  private isEditFromEditor = false;
  public slideCount = [];
  currentSlideIndex: any = null;
  isPreview = false;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    private appSectionTemplateService: AppSectionTemplateService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.init();
    }
  }

  ngOnInit() {
    this.isPreview =
      this.router.url.includes("/preview") == true ? true : false;
    this.isEditFromEditor =
      this.router.url.includes("/microsites/editor") == true ||
      this.router.url.includes("/banner-collection/editor") == true
        ? true
        : false;
    this.isPlatformBrowser = isPlatformBrowser(this.platformId);
    this.proccessDataForDisplay();
  }

  init() {}

  ngAfterViewInit(): void {
    if (this.slideCount.length > 0) {
      this.preparedSlider();
    }

    fromEvent<TouchEvent>(document, "touchstart")
      .pipe(
        zipWith(
          fromEvent<TouchEvent>(document, "touchend").pipe(
            withLatestFrom(fromEvent<TouchEvent>(document, "touchmove"))
          )
        )
      )
      .subscribe(([touchstart, [_, touchmove]]) => {
        const xDiff =
          touchstart.touches[0].clientX - touchmove.touches[0].clientX;
        const currentIndex = this.slideIndex - 1;
        const bodyWidth = document.body.clientWidth;

        if (
          Math.abs(xDiff) > 0.3 * bodyWidth &&
          touchstart.timeStamp <= touchmove.timeStamp
        ) {
          if (xDiff > 0) {
            //right to left swipe (next)
            if (this.slideIndex === this.slideCount.length) {
              this.onImageSelect(0);
              return;
            }
            this.onImageSelect(currentIndex + 1);
          } else {
            //left to right swipe (previous)
            if (currentIndex === 0) {
              this.onImageSelect(this.slideCount.length - 1);
              return;
            }
            this.onImageSelect(currentIndex - 1);
          }
        }
      });
  }

  preparedSlider() {
    let slides = this._document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    let dots = this._document.getElementsByClassName(
      "bullet"
    ) as HTMLCollectionOf<HTMLElement>;
    const animElem = this._document.getElementsByClassName(
      "animation"
    ) as HTMLCollectionOf<HTMLElement>;

    if (this.isEditFromEditor) {
      for (let i = 0; i < slides.length; i++) {
        slides[i].children[0].children[0].classList.remove("animation");
      }
    } else {
      this.slideIndex++;
      if (this.slideIndex > slides.length) {
        this.slideIndex = 1;
      }
      for (let i = 0; i < this.slideCount.length; i++) {
        dots[i].className = dots[i].className.replace(" active-bullet", "");
        slides[i].className = slides[i].className.replace(" opacity", "");
        // reset animation with reflow
        animElem[i].style.animation = "none";
        animElem[i].offsetHeight;
        animElem[i].style.animation = null;
      }
      dots[this.slideIndex - 1].className += " active-bullet";
      slides[this.slideIndex - 1].className += " opacity";
      this.timeOutIDs.push(
        setTimeout(() => {
          this.preparedSlider();
        }, 8000)
      );
    }
  }

  onImageSelect(index: number) {
    this.timeOutIDs.forEach((id) => clearTimeout(id));
    let i: number;
    let slides = this._document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    let dots = this._document.getElementsByClassName(
      "bullet"
    ) as HTMLCollectionOf<HTMLElement>;
    let activeBullet = this._document.getElementsByClassName(
      "active-bullet"
    ) as HTMLCollectionOf<HTMLElement>;
    index++;
    if (index > slides.length) {
      index = 1;
    }
    activeBullet[0].offsetHeight; /* trigger reflow */
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active-bullet", "");
      slides[i].className = slides[i].className.replace(" opacity", "");
    }
    dots[index - 1].className += " active-bullet";
    slides[index - 1].className += " opacity";
    this.slideIndex = index;
    this.timeOutIDs.push(
      setTimeout(() => {
        this.preparedSlider();
      }, 8000)
    );
  }

  proccessDataForDisplay() {
    if (this.form != null) {
      // process property for dynamic render
      for (var i = 0; i < this.form.MetadataList.controls.length; i++) {
        this.slideCount.push(i);
        this.form.MetadataList.controls[i].controls.forEach((item) => {
          if (item.controls.PropertyKey.value == "MAIN_TITLE")
            this.mainTitleForm.push(item.controls);
          else if (item.controls.PropertyKey.value == "SUB_TITLE")
            this.subTitleForm.push(item.controls);
          else if (item.controls.PropertyKey.value == "BUTTON_TEXT")
            this.buttonTextForm.push(item.controls);
          else if (item.controls.PropertyKey.value == "ImageFiles")
            this.imageLinksForm.push(item.controls);
          else if (item.controls.PropertyKey.value == "LINK_TO")
            this.linkToButtonForm.push(item.controls);
        });
      }
      this.currentSlideIndex = this.form.SelectedDataSetIndex;
      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form.IsOnEdit,
        "top-banner"
      );
    }
    // if property is not available in the form then propulate the form from @Input() or the default one.
    if (this.mainTitleForm.length == 0)
      this.mainTitleForm.push(
        this.appSectionTemplateService.createPropertyFormGroup(this.mainTitle)
          .controls
      );
    if (this.subTitleForm.length == 0)
      this.subTitleForm.push(
        this.appSectionTemplateService.createPropertyFormGroup(this.subTitle)
          .controls
      );
    if (this.buttonTextForm.length == 0)
      this.buttonTextForm.push(
        this.appSectionTemplateService.createPropertyFormGroup(this.buttonText)
          .controls
      );
    if (this.imageLinksForm.length == 0)
      this.imageLinksForm.push(
        this.appSectionTemplateService.createPropertyFormGroup(this.imageLinks)
          .controls
      );
    if (this.currentSlideIndex == null) {
      this.currentSlideIndex =
        this.appSectionTemplateService.createPropertyFormGroup(0).controls;
    }
    if (this.linkToButtonForm.length == 0) {
      this.linkToButtonForm.push(
        this.appSectionTemplateService.createPropertyFormGroup("").controls
      );
    }
  }

  onMenuScroll() {
    let el = this._document.getElementById("menu");
    el.scrollIntoView(true);
  }

  onLinkToClick(url) {
    let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

    if (!/^http[s]?:\/\//.test(url)) {
      url = "http://" + url.replaceAll(htmlRegexG, "");
    }
    const link = this._document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.click();
    link.remove();
  }

  removeDomElements() {
    const paragraphs = this._document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    const bullets = this._document.getElementsByClassName(
      "bullet"
    ) as HTMLCollectionOf<HTMLElement>;
    for (var i = paragraphs.length - 1; i >= 0; --i) {
      paragraphs[i].remove();
    }
    for (let i = bullets.length - 1; i >= 0; --i) {
      bullets[i].remove();
    }
  }

  getPropertyValue(data, key) {
    let value;
    data["controls"].forEach((item) => {
      if (item.controls.PropertyKey.value == key)
        value = item.controls.PropertyValue.value;
    });
    if (key == "ImageFiles") value = value[0]?.FileUrl;
    return value;
  }

  trackByIdentity = (index: number, item: any) => item;

  ngOnDestroy(): void {
    this.timeOutIDs.forEach((id) => clearTimeout(id));
    this.removeDomElements();
  }
}
