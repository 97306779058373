<div class="modal-dialog">
	<div class="title">
		<h1 mat-dialog-title>{{ dialogData.title | translate }}</h1>
		<button mat-dialog-close>
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div mat-dialog-content>
		<p>{{ dialogData.description | translate }}</p>
	</div>
</div>
