<div
	id="feedback"
	style="scroll-margin-top: 88px"
	[class.smaller-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
	fxLayout="row"
	fxLayoutAlign="center center"
>
	<div class="feedback-wrapper" *ngIf="!isSendDone" fxFlex="60" fxFlex.lt-lg="70" fxFlex.lg="70" fxFlex.lt-md="100">
		<p class="description">{{ subTitle | translate }}</p>
		<form [formGroup]="feedbackForm" class="text-left">
			<div *ngIf="outletList.length > 1" class="outlet-list" fxLayout="column" fxLayoutGap="8px">
				<label for="outlet">{{ 'SELECT_RESTAURANT' | translate }}</label>
				<mat-radio-group
					fxLayout="row wrap"
					fxLayoutGap="32px"
					formControlName="OutletId"
					aria-label="Select an option"
					class="w-100-p"
				>
					<mat-radio-button
						class="radio-button-start-align mb-16"
						color="primary"
						*ngFor="let outlet of outletList"
						value="{{ outlet.Id }}"
						><span style="white-space: normal">{{ outlet.Name }}</span></mat-radio-button
					>
				</mat-radio-group>
			</div>
			<label for="rating" class="asterisk-if-mandatory">{{ 'RATING' | translate }}</label>
			<app-star-rating
				[value]="feedbackForm?.value?.Rating"
				(starValueChangeEvent)="feedbackForm.patchValue({ Rating: $event })"
			></app-star-rating>
			<label for="firstName">{{ 'FIRST_NAME' | translate }}</label>
			<mat-form-field id="firstName" class="w-100-p" appearance="outline">
				<input matInput formControlName="FirstName" placeholder="{{ 'FIRST_NAME' | translate }}" />
			</mat-form-field>
			<label for="LastName">{{ 'LAST_NAME' | translate }}</label>
			<mat-form-field id="LastName" class="w-100-p" appearance="outline">
				<input matInput formControlName="LastName" placeholder="{{ 'LAST_NAME' | translate }}" />
			</mat-form-field>
			<label for="FullName" class="asterisk-if-mandatory full-name">{{ 'FULL_NAME' | translate }}</label>
			<mat-form-field id="FullName" class="w-100-p full-name" appearance="outline">
				<input matInput formControlName="FullName" placeholder="{{ 'FULL_NAME' | translate }}" />
			</mat-form-field>
			<label for="email">{{ 'EMAIL' | translate }}</label>
			<mat-form-field class="w-100-p" appearance="outline">
				<input matInput formControlName="Email" placeholder="{{ 'EMAIL' | translate }}" />
			</mat-form-field>
			<label for="phone">{{ 'PHONE' | translate }}</label>
			<mat-form-field class="w-100-p" appearance="outline">
				<input matInput formControlName="Phone" placeholder="{{ 'PHONE' | translate }}" />
				<mat-error *ngIf="hasError(FormErrors['Phone'])">
					<span *ngIf="FormErrors?.Phone?.errors?.pattern"
						>{{ 'PHONE' | translate }} {{ 'MUST_VALID' | translate }}</span
					>
				</mat-error>
			</mat-form-field>

			<label for="feedback" class="asterisk-if-mandatory">{{ 'FEEDBACK' | translate }}</label>
			<mat-form-field class="w-100-p textarea" appearance="outline">
				<textarea
					matInput
					placeholder="{{ 'FEEDBACK' | translate }}"
					formControlName="Feedback"
					cdkTextareaAutosize
					maxlength="2000"
					cdkAutosizeMinRows="5"
					cdkAutosizeMaxRows="20"
				></textarea>
				<mat-error *ngIf="hasError(FormErrors['Feedback'])">
					<span *ngIf="FormErrors?.Feedback?.errors?.required"
						>{{ 'Feedback' | translate }} {{ 'IS_REQUIRED' | translate }}</span
					>
				</mat-error>
			</mat-form-field>

			<mat-checkbox
				color="primary"
				class="w-100-p text-left protection-concent asterisk-if-mandatory"
				formControlName="isTermsConditionAccepted"
			>
				{{ 'I_HAVE_READ_THE' | translate }}
				<a (click)="goToDataProtection()"> {{ 'DATA_PROTECTION_LOWER' | translate }} </a>
				{{ 'REGULATION_ACCEPT' | translate }}
			</mat-checkbox>
			<div class="w-100-p text-left">
				<button
					class="submit-btn text-left"
					color="primary"
					(click)="onSubmit()"
					mat-flat-button
					color="primary"
					disabled="{{
						!feedbackForm.valid || !feedbackForm.value.isTermsConditionAccepted || isSendInProgress
					}}"
				>
					{{ 'SEND' | translate }}
				</button>
			</div>
		</form>
	</div>

	<div class="feedback-wrapper" *ngIf="isSendDone">
		<mat-icon class="done-icon" color="primary">check_circle</mat-icon>
		<p>{{ 'FEEDBACK_SUBMITTED_SUCCESSFULLY' | translate }}</p>
		<p>
			{{ 'SUBMIT_ANOTHER' | translate }} <a (click)="goToFeedback()">{{ 'FEEDBACK' | translate }}</a>
			{{ 'OR_GO_TO' | translate }} <a (click)="goToHome()">{{ 'RESTAURANT_SITE' | translate }}</a
			>?
		</p>
	</div>
</div>
