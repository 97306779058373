<div
	id="restaurant-manager-info"
	class="section-padding"
	fxLayout="column"
	[class.mobile-display]="isMobileView"
	fxLayoutAlign="center center"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div class="restaurant-name" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
		<h3 class="page-title">{{ 'ABOUT_US' | translate }}</h3>
		<h1 class="name">{{ selectedOutlet?.value?.Name || 'Outlet Name' }}</h1>
	</div>
	<div
		class="manager-info"
		fxLayout="row"
		fxLayout.sm="column"
		fxLayoutAlign="center"
		fxLayoutAlign.sm="center"
		fxLayout.xs="column"
		fxLayoutAlign.xs="center"
		ngClass.sm="tablet-display"
		ngClass.xs="mobile-display"
	>
		<div class="image" fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
			<img
				src="{{ imageForm?.PropertyValue?.value[0]?.FileUrl }}"
				onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
				alt="card-img"
			/>
		</div>
		<div class="description" fxFlex="50%" fxLayoutGap="10px" fxFlex.sm="100%" fxFlex.xs="100%" fxLayout="column">
			<div class="outlet-head-info" *ngIf="outletHeadEmail" fxLayout="column" fxLayoutGap="5px">
				<p class="name">{{ outletHeadName }}</p>
				<p class="role">{{ outletHeadRole }}</p>
				<p class="email" style="cursor: pointer">
					<a href="mailto:{{ outletHeadEmail }}">{{ outletHeadEmail?.replace('@', '[at]') }}</a>
				</p>
			</div>
			<p class="details" [innerHTML]="sectionBodyContentForm.PropertyValue.value"></p>

			<app-opening-hour [openingHourData]="openingHours"></app-opening-hour>

			<div
				*ngIf="availableEquipmentList.length > 0"
				class="bottom-section"
				fxLayout="column"
				fxLayoutAlign="start start"
			>
				<p class="title">{{ 'EQUIPMENT' | translate }}</p>
				<div class="restaurent-info" fxLayout="row" fxLayoutAlign="space-between">
					<div fxFlex="50">
						<ng-container *ngFor="let item of availableEquipmentList; let i = index">
							<div *ngIf="i <= availableEquipmentList.length / 2" class="item" fxLayout="row">
								<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
								<p>{{ item | translate }}</p>
							</div>
						</ng-container>
					</div>
					<div fxFlex="50">
						<ng-container *ngFor="let item of availableEquipmentList; let i = index">
							<div class="item" *ngIf="i > availableEquipmentList.length / 2" fxLayout="row">
								<img src="../../../../assets/icons/check_circle2.svg" alt="check_circle-icon" />
								<p>{{ item | translate }}</p>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
