import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-ingredients-view-modal',
	templateUrl: './ingredients-view-modal.component.html',
	styleUrls: ['./ingredients-view-modal.component.scss'],
})
export class IngredientsViewModalComponent implements OnInit {
	type: string;
	currentLang: string;
	nutritionValueList: any;
	selectedTab: string;
	selectedTabIndex: number;
	foodItemTitle: string;
	dataCopy: any;

	constructor(
		public dialogRef: MatDialogRef<IngredientsViewModalComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.dataCopy = data;
		this.type = data.type;
		this.foodItemTitle = data?.foodItemTitle;
		this.currentLang = data.currentLang;
		this.selectedTab = data.selectedTab;

		if (this.selectedTab == 'allergic') {
			this.selectedTabIndex = 0;
			this.nutritionValueList = this.data.info?.AllergicInfoDetails;
		} else if (this.selectedTab == 'nutrition') {
			if (!data?.isContainAllergin) {
				this.selectedTabIndex = 0;
			} else {
				this.selectedTabIndex = 1;
			}

			this.nutritionValueList =
				this.data?.info?.FoodItem?.Translations.find((item) => item.Language == this.currentLang)
					?.NutritientValueList || this.data.info?.FoodItem?.NutritientValueList;
		} else {
			if (!data?.isContainAllergin && !data?.isContainNutrition) {
				this.selectedTabIndex = 0;
			} else if (!data?.isContainAllergin || !data?.isContainNutrition) {
				this.selectedTabIndex = 1;
			} else {
				this.selectedTabIndex = 2;
			}
			this.nutritionValueList = this.data.info?.AdditiveInfoDetails;
		}
	}

	ngOnInit(): void {}

	onTabChanged(e) {
		this.selectedTab = e.tab.textLabel;
		if (this.selectedTab == 'ALLERGENS' || this.selectedTab == 'ALLERGENE') {
			this.type = 'allergic';
			this.selectedTabIndex = 0;
			this.nutritionValueList = this.data.info?.AllergicInfoDetails;
		} else if (this.selectedTab == 'NUTRITIONAL VALUES' || this.selectedTab == 'NÄHRWERTE') {
			this.type = 'nutrition';
			if (!this.dataCopy?.isContainAllergin) {
				this.selectedTabIndex = 0;
			} else {
				this.selectedTabIndex = 1;
			}
			this.nutritionValueList =
				this.data?.info?.FoodItem?.Translations.find((item) => item.Language == this.currentLang)
					?.NutritientValueList || this.data.info?.FoodItem?.NutritientValueList;
		} else {
			this.type = 'additive';
			if (!this.dataCopy?.isContainAllergin && !this.dataCopy?.isContainNutrition) {
				this.selectedTabIndex = 0;
			} else if (!this.dataCopy?.isContainAllergin || !this.dataCopy?.isContainNutrition) {
				this.selectedTabIndex = 1;
			} else {
				this.selectedTabIndex = 2;
			}
			this.nutritionValueList = this.data.info?.AdditiveInfoDetails;
		}
	}
}
