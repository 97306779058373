<section
	id="menu"
	style="scroll-margin-top: 88px"
	class="section-padding download-menu-section"
	[class.mobile-display]="isMobileView"
	*ngIf="menuPlans[0].isExist || menuPlans[1].isExist"
>
	<div>
		<p class="section-title">{{ 'MENU_PLAN' | translate }}</p>
		<h1>{{ 'DOWNLOAD_MENU_PLAN' | translate }}</h1>
		<div
			class="menu-plans"
			fxLayout.lt-md="column"
			fxLayout.gt-sm="row"
			[fxLayout.gt-sm]="isMobileView ? 'column' : 'row'"
			fxLayoutGap="16"
			fxLayoutAlign="center center"
		>
			<ng-container *ngFor="let item of menuPlans">
				<button *ngIf="item.isExist" (click)="onClickPlanCard(item.id)" class="card">
					<div>
						<span>
							<h2>{{ ('MENU_PLAN' | translate) + ' ' + item.week }}</h2>
							<p>{{ 'IN_PDF' | translate }}</p>
						</span>
					</div>
				</button>
			</ng-container>
		</div>
	</div>
</section>
