import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getTranslation' })
export class GetTranslationPipe implements PipeTransform {
	transform(translations: { [key: string]: any }[], currentLang: string): any {
		if (!currentLang) {
			throw new Error('currentLang is not defined');
		}
		if (!translations) {
			throw new Error('translations is not defined');
		}

		return translations.find((item) => {
			let covertedLangKey = item['Language'];
			if (covertedLangKey === 'de_CH') covertedLangKey = 'de';
			return covertedLangKey === currentLang;
		});
	}
}
