<div
	id="body-section"
	class="section-padding"
	[class.mobile-display]="isMobileView"
	style="scroll-margin-top: 88px"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div class="container">
		<div class="title h1-48px" [innerHTML]="mainTitleForm.PropertyValue.value"></div>
		<div class="body" [innerHTML]="subTitleForm.PropertyValue.value"></div>
	</div>
</div>
