import { Component, Input, OnInit } from '@angular/core';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-body-section-copy',
	templateUrl: './body-section-copy.component.html',
	styleUrls: ['./body-section-copy.component.scss'],
})
export class BodySectionCopyComponent implements OnInit {
	@Input('form') form: any = null;
	@Input('mainTitle') mainTitle: string = 'Jeden Tag frisch auf den Tisch';
	@Input('subTitle') subTitle: string =
		'Sie haben die Wahl zwischen Gerichten aus aller Welt, beliebten Schweizer Klassikern und einem saisonalen Buffet mit extra viel Frische. Alles wird sorgfältig aus den besten Zutaten zubereitet. Damit der Alltag bunt bleibt, setzen wir auf viel Abwechslung, saisonale Highlights und neue Kreationen im Menüplan.';
	@Input('isMobileView') isMobileView: boolean = false;

	mainTitleForm: any = null;
	subTitleForm: any = null;

	constructor(private appSectionTemplateService: AppSectionTemplateService) {}

	ngOnInit(): void {
		if (this.form !== null) {
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value === 'MAIN_TITLE') {
					this.mainTitleForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'SUB_TITLE') {
					this.subTitleForm = item.controls;
				}
			});

			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'body-section');
		}

		if (this.mainTitleForm === null)
			this.mainTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.mainTitle).controls;

		if (this.subTitleForm === null)
			this.subTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.subTitle).controls;
	}
}
