import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

const header = new HttpHeaders({
	'Content-Type': 'application/json',
	Badge: '6ty084c2-2e1c-451d-80b0-f182abbb9r31',
});
@Injectable({
	providedIn: 'root',
})
export class SharedService {
	siteData: any;
	commonOptions: any = {
		headers: header,
		withCredentials: false,
		observe: 'response',
	};

	private renderer: Renderer2;

	constructor(private http: HttpClient, rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document: Document) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	getMicrositeGlobalPageDetails(assignedRegion: string) {
		const url = environment.SVCmsBusinessService + `/CMS/GetMicrositeGlobalPageDetails?AssignedRegion=${assignedRegion}`;
		return this.http.get(url, this.commonOptions).pipe(map((m: any) => m.body));
	}

	getPageListFromGlobalPageData(data) {
		let list = [];
		data.forEach((x) => list.push(x.MicrositePageInfo.MicrositePageName));
		return list;
	}

	createSnippetScript(data: any) {
		//Script add for head
		let scriptHead = this.renderer.createElement('script');
		scriptHead.text = data?.Head.replace(/^"(.*)"$/, '$1');
		this.renderer.appendChild(this._document.head, scriptHead);

		//Script add for body
		let bodyScript = this.renderer.createElement('noscript');
		bodyScript.textContent = data?.Body.replace(/^"(.*)"$/, '$1');

		// Get the first child of the body
		const firstChild = this._document.body.firstChild;

		// Insert the noscript element before the first child
		if (firstChild) {
			this.renderer.insertBefore(this._document.body, bodyScript, firstChild);
		} else {
			// If there is no first child, simply append the noscript element
			this.renderer.appendChild(this._document.body, bodyScript);
		}
	}
}
