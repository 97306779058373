import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AppSnackbarComponent } from './../components/app-snackbar/app-snackbar.component';
import { GenericInfoModalComponent } from './../components/generic-info-modal/generic-info-modal.component';

const header = new HttpHeaders({
	'Content-Type': 'application/json',
});

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../../assets/i18n/app-shared-template/', '.json');
}

@NgModule({
	imports: [
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			isolate: true,
		}),
	],
})
@Injectable()
export class SvBusinessService {
	private _unsubscribeAll: Subject<void> = new Subject<any>();
	mircrositeData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private matSnackBar: MatSnackBar, private dialog: MatDialog, private translateService: TranslateService) {
		this.openGenericModal();
	}

	confirmUpdate: EventEmitter<boolean> = new EventEmitter();
	closeModal: EventEmitter<boolean> = new EventEmitter();
	openModal: EventEmitter<any> = new EventEmitter();

	showAlertMessage(message: string, type?: 'success' | 'error' | 'warning') {
		let translatedMessage = this.translateService.instant(message);
		if (translatedMessage.length > 90) {
			translatedMessage = translatedMessage.substring(0, 90);
			translatedMessage += '.... ';
		}

		this.matSnackBar.openFromComponent(AppSnackbarComponent, {
			duration: 3000,
			panelClass: 'custom-snackbar',
			verticalPosition: 'bottom',
			horizontalPosition: 'end',
			data: {
				message: translatedMessage,
				status: type,
			},
		});
	}

	openGenericModal() {
		this.openModal.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
			if (res) {
				this.dialog.open(GenericInfoModalComponent, {
					width: '450px',
					panelClass: 'custom-modal',
					data: res,
				});
			}
		});
	}

	getUrlFromLanguageKey(langKey) {
		return langKey.split('_').join('-').toLowerCase();
	}

	getLanguageKeyFromUrl(url) {
		return url.split('-').join('_').toUpperCase();
	}

	public emailValidator(control) {
		let validatingRegex = '^([_a-z0-9A-Z-])+(\\.[_a-zA-Z0-9-]+)*@[_a-z0-9A-Z-]+(\\.[_a-zA-Z0-9-]+)*(\\.)+([a-zA-Z]{2,4})$';
		let validator = new RegExp(validatingRegex);
		if (control.value && control.value.match(validator)) {
			return null;
		} else if (!control.value) {
			return null;
		} else {
			return { email: true };
		}
	}
}
