import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-video-description-section',
	templateUrl: './video-description-section.component.html',
	styleUrls: ['./video-description-section.component.scss'],
})
export class VideoDescriptionSectionComponent implements OnInit {
	@Input('imageFiles') imageFiles: any[] = [
		{
			FileUrl: '../../../assets/images/sustainability-image.svg',
		},
	];
	@Input('mainTitle') mainTitle: string = 'Das Betriebsrestaurant - Dein “Third Place”';
	@Input('bodyText') bodyText: string =
		"<div><strong>Hier fühlst Du dich wohl. Leckeres Essen. Erstklassige Qualität. Gesunde Frische und gute Stimmung. Ein Treffpunkt - nicht nur zum Essen, sondern auch für Gespräche und Kontakte.</strong> <br> <br> Im Mittelpunkt unserer Dienstleistung steht ein gesundes und attraktives Gastronomieangebot - vom Morgenkaffee über das frisch zubereitete Mittagessen bis zum Catering bei Meetings oder Workshops. Kennzeichnend für unser Angebot ist die Vielfalt'</div>";
	@Input('form') form: any = null;
	@Input('buttonText') buttonText: string = 'Jetzt Kontakt aufnehmen';
	@Input('isMobileView')
	isMobileView: boolean = false;
	@Input('buttonURL') buttonURL: string = 'https://www.sv-group.de/de/verantwortung/qualitaet';

	@Input('videoURL') videoURL: string = 'https://vimeo.com/804206503';
	@Input('thumbnail') thumbnail: string = '../../../../assets/images/video.png';
	@Input('videoBodyText') videoBodyText: string =
		'<p>Weitere Informationen sind im <a href="https://www.sv-group.de/de/ueber-uns/publikationen/-dl-/files/svgroup/de/user_upload/Faktenblatt_SV-Deutschland-V2.pdf" target="__blank"> Nachhaltigkeitsbericht </a> und auf dieser <a href="https://www.sv-group.de/de/verantwortung/nachhaltigkeit" target="__blank"> Website </a> enthalten.</p>';

	ImageFilesForm: any = null;
	mainTitleForm: any = null;
	bodyTextForm: any = null;
	buttonTextForm: any = null;
	buttonUrlForm: any = null;
	videoUrlForm: any = null;
	thumbnaiForm: any = null;
	videoBodyTextForm: any = null;

	constructor(private appSectionTemplateService: AppSectionTemplateService, @Inject(DOCUMENT) private _document: Document) {}
	ngOnInit(): void {
		if (this.form !== null) {
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value === 'ImageFiles') {
					this.ImageFilesForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'MAIN_TITLE') {
					this.mainTitleForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'BODY_TEXT') {
					this.bodyTextForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'BUTTON_TEXT') {
					this.buttonTextForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'BUTTON_URL') {
					this.buttonUrlForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'VIDEO_URL') {
					this.videoUrlForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'THUMBNAIL') {
					this.thumbnaiForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'VIDEO_BODY_TEXT') {
					this.videoBodyTextForm = item.controls;
				}
			});
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'card-template');
		}

		if (this.ImageFilesForm === null) {
			this.ImageFilesForm = this.appSectionTemplateService.createPropertyFormGroup(this.imageFiles).controls;
		}

		if (this.mainTitleForm === null) {
			this.mainTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.mainTitle).controls;
		}

		if (this.bodyTextForm === null)
			this.bodyTextForm = this.appSectionTemplateService.createPropertyFormGroup(this.bodyText).controls;

		if (this.buttonTextForm === null)
			this.buttonTextForm = this.appSectionTemplateService.createPropertyFormGroup(this.buttonText).controls;

		if (this.buttonUrlForm === null)
			this.buttonUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.buttonURL).controls;

		if (this.videoUrlForm === null)
			this.videoUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.videoURL).controls;

		if (this.thumbnaiForm === null)
			this.thumbnaiForm = this.appSectionTemplateService.createPropertyFormGroup(this.thumbnail).controls;

		if (this.videoBodyTextForm === null)
			this.videoBodyTextForm = this.appSectionTemplateService.createPropertyFormGroup(this.videoBodyText).controls;

		this.setVideoURL();
	}

	onLinkToClick(url) {
		let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

		if (!/^http[s]?:\/\//.test(url)) {
			url = 'http://' + url.replaceAll(htmlRegexG, '');
		}
		const link = this._document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.click();
		link.remove();
	}

	setVideoURL() {
		this.processVideoURL();

		let srcdoc = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${this.videoURL}><img src=${this.thumbnail}></a>`;

		document.getElementById('myframe').setAttribute('src', this.videoURL);
		document.getElementById('myframe').setAttribute('srcdoc', srcdoc);
	}

	processVideoURL(): void {
		//We always need embed youtube/vimeo URL.
		//Process youtube url: 'https://www.youtube.com/embed/' + video ID.
		//Process vimeo url:  'https://player.vimeo.com/video/' + video ID.

		let isYoutubeUrl = this.videoURL.includes('youtube');

		if (isYoutubeUrl) {
			let id = this.videoURL.split('?v=')[1];
			this.videoURL = 'https://www.youtube.com/embed/' + id;
		} else {
			let vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
			let parsed = this.videoURL.match(vimeoRegex);
			this.videoURL = 'https://player.vimeo.com/video/' + parsed[1];
		}
	}
}
