import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-top-banner',
	templateUrl: './top-banner.component.html',
	styleUrls: ['./top-banner.component.scss'],
})
export class TopBannerComponent implements OnInit {
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('mainTitle') mainTitle: any = 'Here is the website';
	@Input('buttonText') buttonText: any = '';
	@Input('subTitle') subTitle: any =
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus habitasse lacinia et, mauris, morbi.';
	@Input('imageLinks') imageLinks: any[] = [
		{
			FileUrl: 'https://www.sv-group.ch/files/_processed_/f/7/csm_Desktop_DSC4010-1920x600px_850539e648.jpg',
		},
	];
	@Input('form') form: any = null;
	@Input('parentPage') parentPage: string = 'preview';
	mainTitleForm: any = null;
	subTitleForm: any = null;
	imageLinksForm: any = null;
	buttonTextForm: any = null;

	isPreview = false;
	isLandingPage = false;

	constructor(private appSectionTemplateService: AppSectionTemplateService, private router: Router) {}

	ngOnInit() {
		this.isPreview = this.router.url.includes('/editor') == true ? false : true;
		this.isLandingPage = this.parentPage == 'landing' ? true : false;
		this.proccessDataForDisplay();
	}

	proccessDataForDisplay() {
		if (this.form != null) {
			// process property for dynamic render
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value == 'MAIN_TITLE') this.mainTitleForm = item.controls;
				else if (item.controls.PropertyKey.value == 'SUB_TITLE') this.subTitleForm = item.controls;
				else if (item.controls.PropertyKey.value == 'ImageFiles') this.imageLinksForm = item.controls;
				else if (item.controls.PropertyKey.value == 'BUTTON_TEXT') this.buttonTextForm = item.controls;
			});
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form.IsOnEdit, 'top-banner');
		}
		// if property is not available in the form then propulate the form from @Input() or the default one.
		if (this.mainTitleForm == null)
			this.mainTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.mainTitle).controls;
		if (this.subTitleForm == null)
			this.subTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.subTitle).controls;
		if (this.imageLinksForm == null)
			this.imageLinksForm = this.appSectionTemplateService.createPropertyFormGroup(this.imageLinks).controls;
		if (this.buttonTextForm == null)
			this.buttonTextForm = this.appSectionTemplateService.createPropertyFormGroup(this.buttonText).controls;
	}
}
