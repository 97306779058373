<mat-card class="restaurant-details" [class.smaller-display]="isMobileView">
	<div
		*ngIf="menuItem?.FoodItem?.FoodCategory"
		fxLayout="row"
		fxLayoutAlign="space-between center"
		class="offering-line"
	>
		<p class="street">
			{{
				(menuItem?.FoodItem?.Translations | getTranslation : currentLang)?.Offering_Line ??
					menuItem?.FoodItem?.Offering_Line
			}}
		</p>
		<div fxLayout fxLayoutGap="6px">
			<div *ngFor="let icon of menuItem?.FoodItem?.FoodCategory" class="icons flex align-center">
				<img
					[src]="getIcon(icon)"
					customToolTip
					[contentTemplate]="template"
					(mouseover)="mouseOverEvent(icon)"
					alt="icon-image"
				/>
			</div>
			<div *ngFor="let icon of menuItem?.FoodItem?.Icons" class="icons flex align-center">
				<img
					[src]="getIcon(icon)"
					customToolTip
					[contentTemplate]="template"
					(mouseover)="mouseOverEvent(icon)"
					alt="icon-image"
				/>
			</div>
		</div>
	</div>
	<div class="title">
		<h4>
			{{
				(menuItem?.FoodItem?.Translations | getTranslation : currentLang)?.FoodItemTitle ??
					menuItem?.FoodItem?.FoodItemTitle
			}}
		</h4>
	</div>
	<div class="description">
		<p>
			{{
				(menuItem?.FoodItem?.Translations | getTranslation : currentLang)?.FoodItemDescription ??
					menuItem.FoodItem.FoodItemDescription
			}}
		</p>
		<div class="price-type-slots" fxLayoutGap="16px" fxLayout.gt-sm="row" fxLayoutAlign="start center">
			<div
				fxLayout="row"
				class="price-type"
				*ngFor="let price of menuItem?.FoodItem?.Price; let i = index; trackBy: priceTrackByFn"
			>
				<div *ngIf="i < 3">
					<p class="price-class">{{ price.PriceType }}</p>
					<div class="price">
						<span
							><h3>{{ price?.Currency }} {{ price.Amount | priceFormatter }}</h3></span
						>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="origin-info">
		<ng-container *ngFor="let originData of menuItem?.OriginOfFoodDetails; let isLast = last">
			{{ (originData?.Translations | getTranslation : currentLang)?.Category ?? originData?.Category
			}}{{ isLast ? '' : ', ' }}
		</ng-container>
	</div>

	<div fxLayout="row" fxLayoutGap="24" class="footer">
		<button *ngIf="isContainAllergin" class="details" (click)="openFoodMenuModal('allergic', menuItem)">
			<p class="allergene">{{ 'ALLERGENS' | translate }}</p>
		</button>

		<button *ngIf="isContainNutrition" class="details" (click)="openFoodMenuModal('nutrition', menuItem)">
			<p>{{ 'NUTRITIONAL_VALUES' | translate }}</p>
		</button>

		<button *ngIf="isContainAdditives" class="details" (click)="openFoodMenuModal('additive', menuItem)">
			<p>{{ 'ADDITIVES' | translate }}</p>
		</button>
	</div>
</mat-card>

<ng-template #template>
	<div class="tooltip">
		<p class="title">{{ tooltipData?.title | translate }}</p>
		<p class="details">{{ tooltipData?.description | translate }}</p>
	</div>
</ng-template>
