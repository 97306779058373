import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { APP_ID, ApplicationRef, DoBootstrap, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, Router, RouterModule, UrlSerializer, UrlTree } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../../environments/environment';
import { LandingPageService } from '../Landing-page/services/landing-page.service';
import { NotFoundModuleModule } from '../not-found/not-found.module';
import { SharedService } from '../shared/services/shared.service';
import { SharedModule } from '../shared/shared.module';
import { TechnicalErrorComponent } from '../technical-error/technical-error.component';
import { SectionTemplateModule } from './../../../packages/section-template/section-template.module';
import { CookieMessageData } from './models/cookie-elements-text.model';
import { RootDefaultComponent } from './root-default/root-default.component';
import { UpdateService } from './updateSw';

// let cookieConfig: NgcCookieConsentConfig = {
// 	cookie: {
// 		domain: environment.cookieDomain,
// 		expiryDays: 2,
// 	},
// 	palette: {
// 		popup: {
// 			background: '#000',
// 		},
// 		button: {
// 			background: '#599E2E',
// 		},
// 	},
// 	theme: 'edgeless',
// 	type: 'opt-out',
// 	layout: 'my-custom-layout',
// 	layouts: {
// 		'my-custom-layout': '{{buttons}}',
// 	},
// 	elements: {
// 		buttons: `
// 		<span id="cookieconsent:desc" class="cc-message">
// 			{{message}}
// 			<a aria-label="Mehr erfahren" role="button" tabindex="0" class="cc-link" href={{firstPart}} rel="noopener noreferrer nofollow" target="_blank">Mehr erfahren</a>
// 		</span>
// 		<div class="cc-compliance cc-highlight">
// 			<a (click)="delclineCookies()" class="cc-btn cc-deny" role="button" tabindex="0">Ablehnen</a>
// 			<a (click)="acceptCookies()" class="cc-btn cc-allow" tabindex="0" role="button">Cookies erlauben</a>
// 		</div>
//     `,
// 	},
// 	content: {
// 		message:
// 			'Wir verwenden Cookies, um sicherzustellen, dass wir Ihnen die beste Erfahrung auf unserer Website bieten. Wenn Sie diese Seite weiterhin nutzen, gehen wir davon aus, dass Sie damit einverstanden sind.',
// 		firstPart: 'http://svrestaurant.seliselocal.com:4200/SwissM1.sv.com/data-protection',
// 	},
// };
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/app-shared-template/', '.json');
}
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	override parse(url: string): UrlTree {
		return super.parse(url.toLowerCase());
	}
}
@NgModule({
	imports: [
		CommonModule,
		BrowserModule.withServerTransition({ appId: 'sv-microsite' }),
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forRoot([], {
			scrollPositionRestoration: 'top',
		}),
		HttpClientModule,
		TransferHttpCacheModule,
		//NgcCookieConsentModule.forRoot(cookieConfig),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
			defaultLanguage: 'de',
		}),
		SharedModule,
		SectionTemplateModule,
		ServiceWorkerModule.register('/ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		NotFoundModuleModule,
	],
	declarations: [RootDefaultComponent],
	providers: [
		UpdateService,
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer,
		},
		LandingPageService,
	],
})
export class RootModule implements DoBootstrap {
	myDynamicManifest = {
		name: 'SV Restaurant',
		short_name: 'SV Restaurant',
		theme_color: '#1976d2',
		background_color: '#fafafa',
		display: 'standalone',
		scope: '/',
		start_url: 'index.html',
		icons: [
			// {
			// 	src: 'assets/icons/icon-72x72.png',
			// 	sizes: '72x72',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-96x96.png',
			// 	sizes: '96x96',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-128x128.png',
			// 	sizes: '128x128',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-144x144.png',
			// 	sizes: '144x144',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-152x152.png',
			// 	sizes: '152x152',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-192x192.png',
			// 	sizes: '192x192',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-384x384.png',
			// 	sizes: '384x384',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
			// {
			// 	src: 'assets/icons/icon-512x512.png',
			// 	sizes: '512x512',
			// 	type: 'image/png',
			// 	purpose: 'any maskable',
			// },
		],
	};

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(DOCUMENT) private _document: any,
		@Inject(PLATFORM_ID) private platformId: object,
		@Inject(APP_ID) private appId: string,
		private _sharedService: SharedService,
		private update: UpdateService
	) {
		const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
		console.log('platform: ', platform);
	}

	addManifest(domainName) {
		this.myDynamicManifest.start_url = domainName;
		const stringManifest = JSON.stringify(this.myDynamicManifest);
		const blob = new Blob([stringManifest], { type: 'application/json' });
		const manifestURL = URL.createObjectURL(blob);
		document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
	}

	ngDoBootstrap(appRef: ApplicationRef) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json; charset=utf-8',
			Badge: '6ty084c2-2e1c-451d-80b0-f182abbb9r31',
		});

		const requestOptions = { headers: headers };

		let firstPath = this._document.location.pathname.split('/')[1];
		const isLangdingPage = this._document.location.pathname.split('/').includes('de');
		if (firstPath && !isLangdingPage) {
			firstPath = firstPath.toLowerCase();
			const url = environment.SVCmsBusinessService + `/CMS/GetPublishedMicrositeDetails?MicrositeName=${firstPath}`;
			// cookieConfig.content = {
			// 	message: cookieConfig.content.message,
			// 	firstpart: '/' + firstPath + '/data-protection',
			// };

			let selectedLanguage =
				localStorage.getItem('sv-restaurant-language') != undefined
					? localStorage.getItem('sv-restaurant-language')
					: 'de';

			let buttons = CookieMessageData[selectedLanguage];

			// cookieConfig.elements = {
			// 	buttons: buttons,
			// };

			let snippetData;

			const tagSnippetUrl = environment.SVCmsBusinessService + `/CMS/GetTagManagerSnippet`;

			this.http.get(tagSnippetUrl, requestOptions).subscribe({
				next: (data: any) => {
					snippetData = {
						Head: data?.Result?.Head,
						Body: data?.Result?.Body,
					};
					this._sharedService.createSnippetScript(snippetData);
				},
			});

			this.http.get(url, requestOptions).subscribe({
				next: (data: any) => {
					if (data?.IsSuccess) {
						const domainName = data?.Result?.MicrositeDto?.DomainName;
						this._sharedService.siteData = data?.Result;
						this.router.resetConfig([
							{
								path: '',
								redirectTo: domainName,
								pathMatch: 'full',
							},
							{
								path: domainName,
								loadChildren: () => import('../preview/preview.module').then((m) => m.PreviewModule),
							},
							...this.router.config,
						]);

						this.addManifest(domainName);
						appRef.bootstrap(RootDefaultComponent, this._document.getElementById('app'));
					} else {
						// cookieConfig.content = {
						// 	message: cookieConfig.content.message,
						// 	firstpart: '/de/data-protection',
						// };

						let selectedLanguage =
							localStorage.getItem('landing-page-language') != undefined
								? localStorage.getItem('landing-page-language')
								: 'de';

						let buttons = CookieMessageData[selectedLanguage];

						// cookieConfig.elements = {
						// 	buttons: buttons,
						// };

						this.router.resetConfig([
							{
								path: '',
								loadChildren: () =>
									import('../Landing-page/landing-page.module').then((m) => m.LandingPageModule),
							},

							...this.router.config,
						]);
						this.addManifest('/');
						appRef.bootstrap(RootDefaultComponent, this._document.getElementById('app'));
					}
				},
				error: (err) => {
					this.addManifest('/');
					appRef.bootstrap(TechnicalErrorComponent, this._document.getElementById('app'));
				},
			});
		} else {
			// cookieConfig.content = {
			// 	message: cookieConfig.content.message,
			// 	firstpart: '/de/data-protection',
			// };

			const tagSnippetUrl = environment.SVCmsBusinessService + `/CMS/GetTagManagerSnippet`;

			this.http.get(tagSnippetUrl, requestOptions).subscribe({
				next: (data: any) => {
					let snippetData = {
						Head: data?.Result?.Head,
						Body: data?.Result?.Body,
					};
					this._sharedService.createSnippetScript(snippetData);
				},
			});

			let selectedLanguage =
				localStorage.getItem('landing-page-language') != undefined ? localStorage.getItem('landing-page-language') : 'de';

			let buttons = CookieMessageData[selectedLanguage];

			// cookieConfig.elements = {
			// 	buttons: buttons,
			// };

			this.router.resetConfig([
				{
					path: '',
					loadChildren: () => import('../Landing-page/landing-page.module').then((m) => m.LandingPageModule),
				},

				...this.router.config,
			]);
			this.addManifest('/');
			appRef.bootstrap(RootDefaultComponent, this._document.getElementById('app'));
		}
	}
}
