import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialElevationDirective } from './material-elevation.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [ClickOutsideDirective, MaterialElevationDirective],
	exports: [ClickOutsideDirective, MaterialElevationDirective],
	providers: [],
})
export class SharedDirectiveModule {}
