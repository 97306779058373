import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { IGenericInfoModal } from './../../Interfaces/generic-info-modal';
import { SvBusinessService } from './../../services/sv-business.service';

@Component({
	selector: 'app-generic-info-modal',
	templateUrl: './generic-info-modal.component.html',
	styleUrls: ['./generic-info-modal.component.scss'],
})
export class GenericInfoModalComponent implements OnInit {
	langSub: Subscription;
	lang$: Observable<string>;

	modalData: IGenericInfoModal = {
		Title: 'UPDATE',
		ConfirmationMessage: 'ARE_YOU_SURE_ABOUT_THE_UPDATE',
		CancelBtnText: 'NO',
		ConfirmBtnText: 'YES',
	};

	isLoading: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<any>();

	constructor(
		public dialogRef: MatDialogRef<GenericInfoModalComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: IGenericInfoModal,
		private svBusinessService: SvBusinessService,
		private translationService: TranslateService
	) {
		this._unsubscribeAll = new Subject();
		if (!_.isEmpty(this.data)) {
			this.modalData = data;
		}
	}

	ngOnInit(): void {
		this.langSub = this.lang$.subscribe((lang) => this.translationService.use(lang));
		this.svBusinessService.closeModal.pipe(takeUntil(this._unsubscribeAll)).subscribe((res: boolean) => {
			if (res) {
				this.isLoading = false;
				this.dialogRef.close();
			}
		});
	}

	onCancel(isSuccess?: boolean): void {
		this.dialogRef.close(isSuccess);
	}

	onConfirm(): void {
		this.isLoading = true;
		this.svBusinessService.confirmUpdate.emit(true);
	}
}
