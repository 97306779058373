import { Component, Input, OnInit } from "@angular/core";
import { AppSectionTemplateService } from "../../services/section-template.service";

@Component({
  selector: "app-wide-banner-section",
  templateUrl: "./wide-banner-section.component.html",
  styleUrls: ["./wide-banner-section.component.scss"],
})
export class WideBannerSectionComponent implements OnInit {
  @Input("form") form: any = null;
  @Input("mainTitle") mainTitle: string =
    "Schweizer Produkte aus nachhaltigem Anbau";
  @Input("subTitle") subTitle: string =
    "Wussten Sie, dass unser Quinoa aus der Schweiz stammt und nach dem IP-SUISSE-Standard angebaut wird? Und nicht nur das, auch der Tofu wird aus Bio-Suisse-Bohnen hergestellt.";
  @Input("isMobileView") isMobileView: boolean = false;
  @Input("imageFiles") imageFiles: any = {
    FileId: "",
    FileUrl: "../../../../assets/static-image/card-image-3.svg",
  };

  mainTitleForm: any = null;
  subTitleForm: any = null;
  ImageFilesForm: any = null;

  constructor(private appSectionTemplateService: AppSectionTemplateService) {}

  ngOnInit(): void {
    if (this.form !== null) {
      this.form.MetadataList.controls[0].controls.forEach((item) => {
        if (item.controls.PropertyKey.value === "MAIN_TITLE") {
          this.mainTitleForm = item.controls;
        } else if (item.controls.PropertyKey.value === "SUB_TITLE") {
          this.subTitleForm = item.controls;
        } else if (item.controls.PropertyKey.value === "ImageFiles") {
          this.ImageFilesForm = item.controls;
        }
      });

      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form?.IsOnEdit,
        "wide-banner"
      );
    }

    if (this.ImageFilesForm === null) {
      this.ImageFilesForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.imageFiles
        ).controls;
    }

    if (this.mainTitleForm === null) {
      this.mainTitleForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.mainTitle
        ).controls;
    }

    if (this.subTitleForm === null)
      this.subTitleForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.subTitle
        ).controls;
  }
}
