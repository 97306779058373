<div
	class="mat-snack-bar-container"
	[ngClass]="{ success: data.status === 'success', error: data.status === 'error', warning: data.status === 'warning' }"
>
	<div class="custom-snack-bar">
		<mat-icon>{{
			data.status === 'success'
				? 'done'
				: data.status === 'error'
				? 'error_outline'
				: data.status === 'warning'
				? 'report_problem'
				: ''
		}}</mat-icon>
		<span class="message">{{ data.message }}</span>
	</div>
</div>
