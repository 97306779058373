<div
	id="footer-top"
	style="scroll-margin-top: 88px"
	fxLayout="row"
	fxLayoutAlign="center center"
	[class.is-edit]="this.form?.IsOnEdit.value"
>
	<div
		class="banner"
		fxFlex="100%"
		fxLayout="{{ isMobileView ? 'column' : 'row' }}"
		fxLayoutAlign="space-between"
		fxLayout.xs="column"
	>
		<div
			fxFlex="90"
			class="icon"
			fxLayout="{{ isMobileView ? 'column-reverse' : 'row' }}"
			fxLayout.xs="column-reverse"
			fxLayoutAlign="{{ isMobileView ? 'space-between' : 'space-around' }}"
			fxLayoutAlign.xs="space-between"
		>
			<img fxFlex="20" class="sv-icon" [src]="iconSrc" alt="icon" />
			<div
				fxFlex="35"
				class="contents"
				fxLayout="row"
				fxLayoutAlign="{{ isMobileView ? 'space-between' : 'space-around' }}"
				fxLayoutAlign.xs="space-between"
			>
				<div class="list quick-link">
					<p
						*ngFor="let pageName of micrositePageList; index as i"
						class="link"
						(click)="goToPageLinks(pageName)"
					>
						{{ pageName | translate }}
					</p>
				</div>
				<div class="list external-link">
					<p *ngFor="let page of globalPageList" class="link" (click)="goToPageLinks(page.route)">
						{{ page.translateKey | translate }}
					</p>
				</div>
			</div>
			<div fxFlex="40" fxFlex.sm="30" class="list opening-hour" *ngIf="openingHourList.length > 0">
				<div fxLayout="{{ isMobileView ? 'column' : 'row wrap' }}" fxLayout.xs="column" fxLayoutGap="30px">
					<div class="opening-hour-list" *ngFor="let item of openingHourList">
						<div fxLayout="column">
							<p class="name">{{ item.OutletName }}</p>
							<div
								fxLayout="row"
								fxLayoutGap="30px"
								fxLayoutAlign="{{ isMobileView ? 'space-between' : '' }}"
								fxLayoutAlign.xs="space-between"
							>
								<div fxLayout="column">
									<div *ngFor="let schedule of item.Schedules">
										<p class="day">{{ schedule.WeekDay }}</p>
										<div fxLayout="row" fxLayoutAlign="start start">
											<p class="hour">
												{{ schedule.OpeningTime + ' - ' + schedule.ClosingTime }}
											</p>

											<div fxLayout="row" class="category-name" *ngIf="schedule?.CategoryName">
												<span class="line"></span>
												<p>{{ schedule.CategoryName }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			fxFlex="10"
			fxFlex.sm="20"
			fxLayout="{{ isMobileView ? 'center' : 'row wrap' }}"
			fxLayoutAlign="{{ isMobileView ? 'center' : 'row' }}"
			fxLayoutAlign.xs="center "
			fxLayoutGap="48px"
		>
			<div
				class="list"
				*ngIf="
					faceBookUrlForm.PropertyValue.value.length > 0 ||
					instagramUrlForm.PropertyValue.value.length > 0 ||
					youtubeUrlForm.PropertyValue.value.length > 0 ||
					linkedinUrlForm.PropertyValue.value.length > 0
				"
			>
				<div class="social-media" fxLayout="row" fxLayoutGap="10px">
					<img
						*ngIf="faceBookUrlForm.PropertyValue.value.length > 0"
						class="facebook"
						src="../../../../../assets/icons/facebook.svg"
						(click)="goToSocialLink(faceBookUrlForm.PropertyValue.value)"
						alt="icon"
						style="cursor: pointer"
					/>
					<img
						height="32px"
						width="32px"
						*ngIf="instagramUrlForm.PropertyValue.value.length > 0"
						class="instagram"
						style="cursor: pointer"
						src="../../../assets/icons/instagram.svg"
						(click)="
							goToSocialLink('www.instagram.com/svdeutschland/' || instagramUrlForm.PropertyValue.value)
						"
						alt="icon"
					/>
					<img
						*ngIf="youtubeUrlForm.PropertyValue.value.length > 0"
						class="youtube"
						src="../../../../../assets/icons/youtube.svg"
						(click)="goToSocialLink(youtubeUrlForm.PropertyValue.value)"
						alt="icon"
						style="cursor: pointer"
					/>
					<img
						height="32px"
						width="32px"
						*ngIf="linkedinUrlForm.PropertyValue.value.length > 0"
						class="youtube"
						style="cursor: pointer"
						src="../../../assets/icons/linkedin.svg"
						(click)="
							goToSocialLink(
								'www.linkedin.com/company/sv-deutschland/' || linkedinUrlForm.PropertyValue.value
							)
						"
						alt="icon"
					/>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="footer-bottom" [class.mobile-display]="isMobileView" fxLayout="row" fxLayoutAlign="center center">
	<mat-icon class="material-symbols-outlined">copyright</mat-icon>
	<p>{{ copyRightYearstring + ' ' + micrositeName }}</p>
</div>
