import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'app-star-rating',
	templateUrl: './app-star-rating.component.html',
	styleUrls: ['./app-star-rating.component.scss'],
})
export class AppStarRatingComponent {
	@Input('value') value: number = 5;
	@Output() starValueChangeEvent = new EventEmitter<number>();

	setValue(star: number) {
		this.value = star;
		this.starValueChangeEvent.emit(star);
	}
}
