<div
	id="oultet-list"
	*ngIf="outletData?.length > 1"
	class="section-padding"
	style="scroll-margin-top: 88px; scroll-margin-left: 80px"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
	fxLayoutAlign="center center"
>
	<div class="menu-buttons" fxLayout="row" style="overflow-x: auto">
		<div class="button-tab-group" *ngFor="let outlet of outletData; let i = index">
			<div
				class="button-tab"
				(click)="onOutletSelection(outlet, i)"
				[class.selected-outlet]="selectedOutlet?.value?.Id === outlet?.Id"
				mat-flat-button
			>
				<span style="white-space: normal">{{ outlet?.Name }}</span>
			</div>
		</div>
	</div>
</div>
