import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AppSnackbarComponent } from './../components/app-snackbar/app-snackbar.component';

@Injectable()
export class SvBusinessService {
	mircrositeData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private matSnackBar: MatSnackBar, private translateService: TranslateService) {}

	showAlertMessage(message: string, type?: 'success' | 'error' | 'warning' | 'button-view') {
		let translatedMessage = this.translateService.instant(message);
		if (translatedMessage.length > 90) {
			translatedMessage = translatedMessage.substring(0, 90);
			translatedMessage += '.... ';
		}

		let duration = 3000;
		if (type && type == 'button-view') duration = 9000;

		this.matSnackBar.openFromComponent(AppSnackbarComponent, {
			duration: duration,
			panelClass: 'custom-snackbar',
			verticalPosition: 'bottom',
			horizontalPosition: 'end',
			data: {
				message: translatedMessage,
				status: type,
			},
		});
	}

	getUrlFromLanguageKey(langKey) {
		return langKey.split('_').join('-').toLowerCase();
	}

	getLanguageKeyFromUrl(url) {
		return url.split('-').join('_').toUpperCase();
	}

	public emailValidator(control) {
		let validatingRegex = '^([_a-z0-9A-Z-])+(\\.[_a-zA-Z0-9-]+)*@[_a-z0-9A-Z-]+(\\.[_a-zA-Z0-9-]+)*(\\.)+([a-zA-Z]{2,4})$';
		let validator = new RegExp(validatingRegex);
		if (control.value && control.value.match(validator)) {
			return null;
		} else if (!control.value) {
			return null;
		} else {
			return { email: true };
		}
	}
}
