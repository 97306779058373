<div
	id="header-menu"
	class="container"
	fxLayout="row"
	fxLayoutAlign="space-between center"
	[class.smaller-display]="isMobileView"
	[class.fixed-header]="isPreview"
>
	<div class="left-side" (click)="navigateTo('HOME')" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">
		<img [src]="iconSrc" alt="icon" />
		<p class="name">{{ micrositeName }}</p>
	</div>
	<div class="right-side" fxLayout="row">
		<div
			(click)="navigateTo(section)"
			class="sections ml-24"
			[class.selectedSection]="section == selectedPageName"
			*ngFor="let section of micrositePageList; let i = index"
		>
			{{ section | translate }}
		</div>
		<div
			*ngIf="!isFromEditorPanel && languages.length > 1"
			class="sections language-section ml-24"
			fxLayout="row"
			(click)="onOpenLanguageList()"
		>
			<p class="sections language-key">{{ selectedLanguage | uppercase }}</p>
			<mat-icon class="drop-down-icon" class="material-symbols-outlined">expand_more</mat-icon>
			<div
				class="language-list"
				*ngIf="isOpenLanguageList"
				(clickOutside)="onClickedOutside($event, ['lang-key', 'language-key', 'drop-down-icon', 'mat-icon'])"
			>
				<p
					class="lang-key"
					(click)="onLanguageSelect(language)"
					*ngFor="let language of languages; let i = index"
				>
					{{ language | uppercase }}
				</p>
			</div>
		</div>
		<div class="mobile-view-right-side" (click)="onHamburgerOpen()">
			<mat-icon *ngIf="!isOpenMenu" class="material-symbols-outlined"> menu </mat-icon>
			<mat-icon
				*ngIf="isOpenMenu"
				(click)="onHamburgerClose(); $event.stopPropagation()"
				class="material-symbols-outlined hamburger-close-icon"
				>close</mat-icon
			>
			<div class="hamburger-menu" [style.top.px]="divHeight" *ngIf="isOpenMenu">
				<div class="body" fxLayout="column">
					<div
						class="list-container"
						[class.selected-hamburger-menu]="section == selectedPageName"
						*ngFor="let section of micrositePageList; let i = index"
					>
						<p (click)="onPageSelect(section, i)" class="list">
							<span>{{ section | translate }}</span>
						</p>
					</div>

					<div
						*ngIf="!isFromEditorPanel && languages.length > 1"
						class="language-switcher"
						(click)="onHamburgerLanguage()"
						fxLayout="row"
						fxLayoutAlign="center center"
					>
						<p
							class="ham-lang-key"
							(click)="onLanguageSelect(language)"
							*ngFor="let language of languages; let i = index"
							[class.selected]="language == selectedLanguage"
						>
							{{ language | uppercase }}
							<span *ngIf="i !== languages.length - 1" class="seperator"> | </span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
