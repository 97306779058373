import { Injectable } from '@angular/core';

import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class UpdateService {
	constructor(private swUpdate: SwUpdate) {
		console.log('SW Incoming');
		if (!this.swUpdate.isEnabled) {
			console.log('Nope 🙁');
		}
		if (this.swUpdate.isEnabled) {
			console.log('SW Enabled');
			this.swUpdate.versionUpdates.subscribe((res) => {
				console.log(res);
				console.log('SW Available');
				console.log('New version available. Load New Version?');
				// window.location.reload();
			});
		}
	}

	public checkForUpdates(): void {
		console.log('SW check for update.');
		this.swUpdate.versionUpdates.subscribe((event) => document.location.reload());
	}
}
