import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SvBusinessService } from '../../../shared/services/sv-business.service';
import { IOpeningHour } from '../../models/microsite-frontend.model';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-footer-section',
	templateUrl: './footer-section.component.html',
	styleUrls: ['./footer-section.component.scss'],
})
export class FooterSectionComponent implements OnInit {
	@Input('copyRightYear') copyRightYearstring: any = new Date().getFullYear();
	@Input('footerText') footerText: string = '2022 Gastronomie Circle';
	@Input('micrositeName') micrositeName: string = 'SV (Deutschland) GmbH';
	@Input('iconSrc') iconSrc: string = '../../../../../assets/icons/sv_restaurent_icon.svg';
	@Input('openingHourList') openingHourList: IOpeningHour[] = [];
	@Input('instagramUrl') instagramUrl: string = '';
	@Input('linkedinUrl') linkedinUrl: string = '';
	@Input('facebookUrl') facebookUrl: string = '';
	@Input('youtubeUrl') youtubeUrl: string = '';

	@Input('form') form: any = null;
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('micrositePageList') micrositePageList: string[] = ['Kontakt', 'Über die SV Group', 'Jobs'];
	@Input('micrositePageLink') micrositePageLink: string[] = [
		'https://www.sv-group.de/de/kontakt',
		'https://www.sv-group.de/de/ueber-uns',
		'https://www.sv-group.de/de/jobs-karriere',
	];
	@Input('globalPageList') globalPageList = [
		{
			translateKey: 'DISCLAIMER',
			route: 'disclaimer',
		},
		{
			translateKey: 'DATA_PROTECTION',
			route: 'data-protection',
		},
		{
			translateKey: 'IMPRINT',
			route: 'imprint',
		},
	];
	@Output('selectedPageName') selectedPageName: EventEmitter<string> = new EventEmitter();
	private _unsubscribeAll: Subject<void> = new Subject<any>();

	faceBookUrlForm: any = null;
	instagramUrlForm: any = null;
	linkedinUrlForm: any = null;
	youtubeUrlForm: any = null;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private appSectionTemplateService: AppSectionTemplateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _svBusinessService: SvBusinessService
	) {}

	ngOnInit(): void {
		if (this.form != null) {
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value == 'INSTAGRAM_URL') this.instagramUrlForm = item.controls;
				else if (item.controls.PropertyKey.value == 'LINKEDIN_URL') this.linkedinUrlForm = item.controls;
				else if (item.controls.PropertyKey.value == 'FACEBOOK_URL') this.faceBookUrlForm = item.controls;
				else if (item.controls.PropertyKey.value == 'YOUTUBE_URL') this.youtubeUrlForm = item.controls;
			});
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'footer-top');
		}

		if (this.router.url.includes('template-preview')) {
			this.facebookUrl = 'www.facebook.com';
			this.youtubeUrl = 'www.youtube.com';
			this.instagramUrl = 'www.instagram.com';
		}

		if (this.instagramUrlForm == null)
			this.instagramUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.instagramUrl).controls;
		if (this.linkedinUrlForm == null)
			this.linkedinUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.linkedinUrl).controls;
		if (this.faceBookUrlForm == null)
			this.faceBookUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.facebookUrl).controls;
		if (this.youtubeUrlForm == null)
			this.youtubeUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.youtubeUrl).controls;

		this.appSectionTemplateService.goToHomeFromFeedback
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res: boolean) => {
				if (res) {
					this.goToPageLinks('HOME');
					this.appSectionTemplateService.goToHomeFromFeedback.emit(false);
				}
			});

		this.appSectionTemplateService.goToDataProtectionFromFeedback
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res: boolean) => {
				if (res) {
					this.openDataPolicy();
					this.appSectionTemplateService.goToDataProtectionFromFeedback.emit(false);
				}
			});
	}

	goToSocialLink(url: string) {
		if (!/^http[s]?:\/\//.test(url)) {
			url = 'http://' + url;
		}

		const link = this._document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.click();
		link.remove();
	}

	openDisclaimer() {
		this.router.navigate(['disclaimer'], { relativeTo: this.activatedRoute });
		this.selectedPageName.emit('Disclaimer');
	}

	openDataPolicy() {
		this.router.navigate(['data-protection'], { relativeTo: this.activatedRoute });
		this.selectedPageName.emit('Data-protection');
	}

	openFeedback(): void {
		this.router.navigate(['feedback'], { relativeTo: this.activatedRoute });
		this.selectedPageName.emit('Feedback');
	}

	goToPageLinks(pageName) {
		// will have to find some way to diffentiate between different page behaviour
		if (
			pageName == 'Kontakt' ||
			pageName == 'Über die SV Group' ||
			pageName == 'Jobs' ||
			pageName == 'Contact' ||
			pageName == 'About SV Group' ||
			pageName == 'Jobs'
		) {
			let externalPageIndex = this.micrositePageList.findIndex((x) => x == pageName);
			this.goToSocialLink(this.micrositePageLink[externalPageIndex]);
		} else if (
			this.router.url.includes('template-preview') ||
			this.router.url.includes('preview') ||
			this.router.url.split('/').length == 3
		) {
			this.router.navigate([this._svBusinessService.getUrlFromLanguageKey(pageName)], {
				relativeTo: this.activatedRoute,
			});
			this.selectedPageName.emit(pageName);
		} else {
			let url = this._document.location.origin + '/de/' + this._svBusinessService.getUrlFromLanguageKey(pageName);
			if (!/^http[s]?:\/\//.test(url)) {
				url = 'https://' + url;
			}

			const link = this._document.createElement('a');
			link.target = '_blank';
			link.href = url;
			link.click();
			link.remove();
		}
	}
}
