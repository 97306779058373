import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { SvBusinessService } from '../../../shared/services/sv-business.service';
import { IFeedbackData } from '../../models/microsite-frontend.model';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-feedback-section',
	templateUrl: './feedback-section.component.html',
	styleUrls: ['./feedback-section.component.scss'],
})
export class FeedbackComponent implements OnInit {
	feedbackForm: FormGroup;
	@Input('title') title: string = 'FEEDBACK';
	@Input('subTitle') subTitle: string = 'FEED_BACK_SUB_TITLE';
	@Input('form') form: any = null;
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('isFeedbackFormDisabled') isFeedbackFormDisabled: boolean = false;

	titleForm: any = null;
	subTitleForm: any = null;
	isSendDone: boolean = false;
	isSendInProgress: boolean = false;
	micrositeData: any;
	outletList: any = [];

	private _unsubscribeAll: Subject<void> = new Subject<any>();

	constructor(
		private fb: FormBuilder,
		private appSectionTemplateService: AppSectionTemplateService,
		private svBusinessService: SvBusinessService,
		@Inject(DOCUMENT) private _document: Document,
		private router: Router,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.svBusinessService.mircrositeData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((resp) => {
			this.micrositeData = resp;
		});

		this.isFeedbackFormDisabled = this.router.url.includes('/template-preview') == true ? true : false;
		if (this.form !== null) {
			this.form?.MetadataList?.controls[0]?.controls.forEach((item) => {
				if (item.controls.PropertyKey.value === 'FEEDBACK_TITLE') {
					this.titleForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'FEEDBACK_SUBTITLE') {
					this.subTitleForm = item.controls;
				}
			});
			if (this.form?.OutletList) {
				this.outletList = this.form?.OutletList?.value;
			}
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'feedback');
		}

		this.initForm();

		if (this.titleForm === null)
			this.titleForm = this.appSectionTemplateService.createPropertyFormGroup(this.title).controls;

		if (this.subTitleForm === null)
			this.subTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.subTitle).controls;
	}

	hasError(FormControl: AbstractControl) {
		return FormControl?.errors && (FormControl.touched || FormControl.dirty);
	}

	get FormErrors() {
		return this.feedbackForm.controls;
	}

	initForm() {
		const phoneRegex = /^[+0-9 -]{10,15}$/;
		this.feedbackForm = this.fb.group({
			Feedback: [{ value: '', disabled: this.isFeedbackFormDisabled }, [Validators.required]],
			FirstName: [{ value: '', disabled: this.isFeedbackFormDisabled }],
			LastName: [{ value: '', disabled: this.isFeedbackFormDisabled }],
			FullName: [{ value: null, disabled: this.isFeedbackFormDisabled }],
			Phone: [{ value: '', disabled: this.isFeedbackFormDisabled }, [Validators.pattern(phoneRegex)]],
			Email: [
				{ value: '', disabled: this.isFeedbackFormDisabled },
				[Validators.email, this.svBusinessService.emailValidator],
			],
			isTermsConditionAccepted: [{ value: '', disabled: this.isFeedbackFormDisabled }, Validators.required],
			Rating: [{ value: 5, disabled: this.isFeedbackFormDisabled }, [Validators.required]],
			OutletId: [this.outletList.length > 0 ? this.outletList[0].Id : ''],
		});
	}

	onSubmit() {
		this.isSendInProgress = true;
		if (this.feedbackForm.value.FullName !== null) {
			return;
		}
		let data: IFeedbackData = {
			Firstname: this.feedbackForm.value.FirstName,
			LastName: this.feedbackForm.value.LastName,
			Email: this.feedbackForm.value.Email,
			Feedback: this.feedbackForm.value.Feedback,
			Phone: this.feedbackForm.value.Phone,
			Rating: this.feedbackForm.value.Rating,
			RestaurantName: this.micrositeData.Name,
			MicrositeId: this.micrositeData.ItemId,
			Language: this.micrositeData.Language,
			OutletId: this.feedbackForm.value.OutletId,
		};
		this.appSectionTemplateService
			.sendFeedBack(data)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: (res: any) => {
					if (res && res['body'] && res['body'].Result) {
						this.feedbackForm.reset();
						this.svBusinessService.showAlertMessage(
							this.translateService.instant('FEEDBACK_SENT'),
							'success'
						);
						this.isSendDone = true;
						this.isSendInProgress = false;
						const el = this._document.getElementById('feedback');
						el.scrollIntoView(true);
					}
				},
				error: (err) => {
					this.svBusinessService.showAlertMessage(
						this.translateService.instant('SOMETHING_WENT_WRONG'),
						'error'
					);
				},
			});
	}

	goToFeedback(): void {
		this.isSendDone = false;
		this.initForm();
	}

	goToHome(): void {
		this.appSectionTemplateService.goToHomeFromFeedback.emit(true);
	}

	goToDataProtection(): void {
		let path = '/';
		let routerPath = this.router.url.split('/');
		for (let i = 1; i < routerPath.length - 1; i++) path += routerPath[i] + '/';
		path += 'data-protection';
		const url = this.router.serializeUrl(this.router.createUrlTree([path]));
		const link = this._document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.click();
		link.remove();
	}
}
