import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PageIndex } from '../../models/microsite-frontend.model';
import { SvBusinessService } from './../../../shared/services/sv-business.service';

@Component({
	selector: 'app-header-menu',
	templateUrl: './header-menu.component.html',
	styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
	@Input('iconSrc') iconSrc: string = '../../../../../assets/icons/header_icon.svg';
	@Input('micrositeName') micrositeName: string = 'Microsite Name';
	@Input('languages') languages: string[] = ['de'];
	@Input('selectedLanguage') selectedLanguage = 'de';
	@Input('isMobileView') isMobileView: boolean = false;
	@Input('selectedPageName') selectedPageName: string;
	@Input('selectedSectionIndex') selectedSectionIndex: number = 0;
	@Input('micrositePageList') micrositePageList = [];
	@Input('globalPageList') globalPageList = [];

	@Output('onLanguageChangeEvent') onLanguageChangeEvent: EventEmitter<string> = new EventEmitter();

	private _unSubscribeAll: Subject<any>;
	isOpenMenu: boolean = false;
	isOpenLanguageList: boolean = false;
	isOpenLanguageListAtHamburger: boolean = false;
	panelOpenState: boolean = false;
	isFromEditorPanel: boolean = false;
	isPreview = true;
	divHeight: number = 88;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _svBusinessService: SvBusinessService,
		private elementRef: ElementRef
	) {
		this._unSubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this.isFromEditorPanel = this.router.url.includes('/microsites/editor') == true ? true : false;
		this.isPreview = this.router.url.includes('/editor') == true ? false : true;
		if (this.iconSrc == null) this.iconSrc = '../../../../../assets/icons/Gastronomie_Circle_Icon.svg';
	}

	changePageSelection() {
		let url = this.router.url.split('/');
		this.selectedSectionIndex = PageIndex[url[url.length - 1]];
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				let url = this.router.url.split('/');
				this.selectedSectionIndex = PageIndex[url[url.length - 1]];
			}
		});

		if (this.selectedSectionIndex == undefined) this.selectedSectionIndex = 0;
	}

	onHamburgerOpen() {
		this.isOpenMenu = true;
	}

	onHamburgerClose() {
		this.isOpenMenu = false;
		this.isOpenLanguageListAtHamburger = false;
	}

	onClickedOutside($event, className) {
		const classes = className;

		let open = false;
		for (const c of $event.target.classList) {
			if (classes.indexOf(c) > -1) {
				open = true;
			}
		}
		if (!open) {
			if (className[0] == 'image') this.onHamburgerClose();
			if (className[0] == 'lang-key') this.onLanguageListClose();
			if (className[0] == 'ham-lang-key') {
				this.isOpenLanguageListAtHamburger = false;
			}
		}
	}

	onOpenLanguageList() {
		this.isOpenLanguageList = !this.isOpenLanguageList;
	}

	onLanguageListClose() {
		this.isOpenLanguageList = false;
	}

	onLanguageSelect(lang) {
		// this.onLanguageListClose();
		this.selectedLanguage = lang;
		this.onLanguageChangeEvent.emit(lang);
	}

	onHamburgerLanguage() {
		this.isOpenLanguageListAtHamburger = !this.isOpenLanguageListAtHamburger;
	}

	onPageSelect(pageName: string, index) {
		this.selectedPageName = pageName;
		this.selectedSectionIndex = index;
		let url = this._svBusinessService.getUrlFromLanguageKey(pageName);
		setTimeout(() => {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			this.onHamburgerClose();
		}, 1000);
	}

	openPage(pageName) {
		this.selectedPageName = pageName;
		setTimeout(() => {
			this.router.navigate([this._svBusinessService.getUrlFromLanguageKey(pageName)], {
				relativeTo: this.activatedRoute,
			});
			this.onHamburgerClose();
		}, 1000);
	}

	navigateTo(pageName: string) {
		let url = this._svBusinessService.getUrlFromLanguageKey(pageName);
		if (this.selectedPageName == pageName && pageName == 'HOME') {
			let el = document.getElementById('top-banner');
			el.scrollIntoView(true);
			return;
		}

		this.selectedPageName = pageName;
		this.router.navigate([url], { relativeTo: this.activatedRoute });
	}

	ngAfterViewInit() {
		this.setDivHeight();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.setDivHeight(); // Recalculate height on window resize
	}

	private setDivHeight() {
		const myDiv = this.elementRef.nativeElement.querySelector('#header-menu');
		if (myDiv) {
			this.divHeight = Number(window.getComputedStyle(myDiv).height.slice(0, -2)) - 2;
		}
	}

	ngOnDestroy() {
		this._unSubscribeAll.unsubscribe();
		this.onHamburgerClose();
	}
}
