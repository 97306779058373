<div
	*ngIf="isPreview"
	id="top-banner"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="form?.IsOnEdit.value"
	style="scroll-margin-top: 88px"
	[class.top-margin]="isPreview"
>
	<div class="slideshow-container" [style.minHeight.px]="carouselHeight">
		<div
			style="scroll-margin-top: 88px"
			*ngFor="let slide of slideCount"
			class="mySlides"
			[class.opacity]="currentSlideIndex.value === slide"
		>
			<div class="slide">
				<img class="animation" src="{{ imageLinksForm[slide]?.PropertyValue?.value[0]?.FileUrl }}" />
				<div class="content-wrapper content-position">
					<div class="main-title" [innerHTML]="mainTitleForm[slide]?.PropertyValue.value"></div>
					<div class="sub-title mt-8" [innerHTML]="subTitleForm[slide]?.PropertyValue.value"></div>
					<button
						[innerHTML]="buttonTextForm[slide]?.PropertyValue?.value"
						*ngIf="buttonTextForm[slide]?.PropertyValue?.value?.length >= 1"
						mat-flat-button
						class="button mt-32"
						(click)="onLinkToClick(linkToButtonForm[slide]?.PropertyValue?.value)"
					></button>
				</div>
			</div>
		</div>
		<div class="shadow-wrap"></div>
		<div class="controller content-padding" fxLayout="row" *ngIf="isPlatformBrowser && slideCount.length > 0">
			<div
				*ngFor="let slide of slideCount; let i = index"
				(click)="onImageSelect(i)"
				class="bullet"
				[class.active-bullet]="currentSlideIndex == slide"
			></div>
		</div>
	</div>
</div>

<div
	*ngIf="!isPreview"
	id="top-banner"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="form?.IsOnEdit?.value"
	style="scroll-margin-top: 88px"
>
	<div class="slideshow-container" [style.minHeight.px]="carouselHeight">
		<div
			style="scroll-margin-top: 88px"
			*ngFor="let slide of this.form.MetadataList.controls; let i = index; trackBy: trackByIdentity"
			class="mySlides"
			[class.opacity]="currentSlideIndex.value === i"
		>
			<div class="slide">
				<img class="animation" src="{{ getPropertyValue(slide, 'ImageFiles') }}" />
				<div class="content-wrapper content-position">
					<div class="main-title" [innerHTML]="getPropertyValue(slide, 'MAIN_TITLE')"></div>
					<div class="sub-title mt-8" [innerHTML]="getPropertyValue(slide, 'SUB_TITLE')"></div>
					<button
						[innerHTML]="buttonTextForm[i]?.PropertyValue?.value"
						*ngIf="buttonTextForm[i]?.PropertyValue?.value?.length >= 1"
						mat-flat-button
						class="button mt-32"
						(click)="onLinkToClick(linkToButtonForm[i]?.PropertyValue?.value)"
					></button>
				</div>
			</div>
		</div>
		<div class="shadow-wrap"></div>
		<div
			class="controller content-padding"
			fxLayout="row"
			*ngIf="isPlatformBrowser && this.form.MetadataList.controls.length > 0"
		>
			<div
				*ngFor="let slide of this.form.MetadataList.controls; let i = index"
				(click)="onImageSelect(i)"
				class="bullet"
				[class.active-bullet]="currentSlideIndex == slide"
			></div>
		</div>
	</div>
</div>
