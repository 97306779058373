<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="modal-dialog">
	<div mat-dialog-content>
		<div class="body">
			<div class="flex justify-between align-center">
				<h1>{{ modalData.Title | translate }}</h1>
				<button mat-icon-button (click)="onCancel()">
					<mat-icon>close</mat-icon>
				</button>
			</div>
			<p>{{ modalData.ConfirmationMessage | translate }}</p>
		</div>
		<div class="bottom flex justify-between align-center">
			<button mat-flat-button (click)="onCancel()" class="modal-cancel-btn">
				{{ modalData.CancelBtnText | translate }}
			</button>
			<button
				class="flex justify-center align-center"
				mat-flat-button
				color="primary"
				(click)="onConfirm()"
				cdkFocusInitial
			>
				{{ modalData.ConfirmBtnText | translate }}
			</button>
		</div>
	</div>
</div>
