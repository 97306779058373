<section
	id="food-origin"
	class="section-padding"
	style="scroll-margin-top: 150px"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
>
	<div
		fxLayout="row wrap"
		fxLayoutAlign="space-between"
		fxLayoutGap.lt-sm="16px grid"
		fxLayoutGap.lt-lg="16px grid"
		fxLayoutGap="16px grid"
		class="card-area"
	>
		<div class="card-section" fxFlex="33%" fxFlex.xs="100%" fxFlex.md="50%" fxFlex.lg="33%" fxFlex.sm="50%">
			<div class="card" fxLayout="column">
				<div class="image">
					<img
						[src]="imageFiles1Form?.PropertyValue?.value[0]?.FileUrl"
						onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
						alt="card-image"
					/>
				</div>
				<div class="card-body">
					<div class="title" [innerHTML]="mainTitle1Form?.PropertyValue?.value"></div>
					<div class="body" [innerHTML]="subTitle1Form?.PropertyValue?.value"></div>
				</div>
			</div>
		</div>
		<div class="card-section" fxFlex="33%" fxFlex.xs="100%" fxFlex.md="50%" fxFlex.lg="33%" fxFlex.sm="50%">
			<div class="card" fxLayout="column">
				<div class="image">
					<img
						[src]="imageFiles2Form?.PropertyValue?.value[0]?.FileUrl"
						onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
						alt="card-image"
					/>
				</div>
				<div class="card-body">
					<div class="title" [innerHTML]="mainTitle2Form?.PropertyValue.value"></div>
					<div class="body" [innerHTML]="subTitle2Form?.PropertyValue.value"></div>
				</div>
			</div>
		</div>
		<div class="card-section" fxFlex="33%" fxFlex.xs="100%" fxFlex.md="50%" fxFlex.lg="33%" fxFlex.sm="50%">
			<div class="card" fxLayout="column">
				<div class="image">
					<img
						[src]="imageFiles3Form?.PropertyValue?.value[0]?.FileUrl"
						onerror="this.src='../../../../assets/images/dummy-image-square.jpg';"
						alt="card-image"
					/>
				</div>
				<div class="card-body">
					<div class="title" [innerHTML]="mainTitle3Form?.PropertyValue?.value"></div>
					<div class="body" [innerHTML]="subTitle3Form?.PropertyValue?.value"></div>
				</div>
			</div>
		</div>
	</div>
</section>
