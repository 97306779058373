import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppOverlayModule } from '../shared/components/app-overlay/app-overlay.module';
import { SharedDirectiveModule } from '../shared/directives/shared-directive.module';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { BodySectionCopyComponent } from './components/body-section-copy/body-section-copy.component';
import { BodySectionComponent } from './components/body-section/body-section.component';
import { ContactUsCopyComponent } from './components/contact-us-copy/contact-us-copy.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DescriptionSectionComponent } from './components/description-section/description-section.component';
import { FeedbackComponent } from './components/feedback-section/feedback-section.component';
import { FoodOriginSection } from './components/food-origin-section/food-origin-section.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { FooterSectionComponent } from './components/footer-section/footer-section.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { LeftImageCardSectionComponent } from './components/left-image-card-section/left-image-card-section.component';
import { MenuPlanComponent } from './components/menu-plan/menu-plan.component';
import { OutletInfoComponent } from './components/outlet-info/outlet-info.component';
import { OutletListComponent } from './components/outlet-list/outlet-list.component';
import { OutletManagerComponent } from './components/outlet-manager/outlet-manager.component';
import { IngredientsViewModalComponent } from './components/restaurant-menu-section/ingredients-view-modal/ingredients-view-modal.component';
import { ModalDialogComponent } from './components/restaurant-menu-section/modal-dialog/modal-dialog.component';
import { GetFoodItemTypeIcon } from './components/restaurant-menu-section/pipe/getFoodItemTypeIcon.pipe';
import { GetTranslationPipe } from './components/restaurant-menu-section/pipe/getTranslation.pipe';
import { IsNutritionalValueExistPipe } from './components/restaurant-menu-section/pipe/isNutritionValueExist.pipe';
import { PriceFormatterPipe } from './components/restaurant-menu-section/pipe/priceFormatter.pipe';
import { CustomToolTipComponent } from './components/restaurant-menu-section/restaurant-menu-food-item/custom-tool-tip/custom-tool-tip.component';
import { RestaurantMenuFoodItemComponent } from './components/restaurant-menu-section/restaurant-menu-food-item/restaurant-menu-food-item.component';
import { ToolTipRendererDirective } from './components/restaurant-menu-section/restaurant-menu-food-item/tool-tip-renderer.directive';
import { RestaurantMenuSectionComponent } from './components/restaurant-menu-section/restaurant-menu-section.component';
import { RightImageCardSectionComponent } from './components/right-image-card-section/right-image-card-section.component';
import { SkeletonLoader } from './components/skeleton-loader/skeleton-loader.component';
import { TextOnlySectionComponent } from './components/text-only-section/text-only-section.component';
import { TopBannerCarouselComponent } from './components/top-banner-carousel/top-banner-carousel.component';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { VideoDescriptionSectionComponent } from './components/video-description-section/video-description-section.component';
import { VideoSectionComponent } from './components/video-section/video-section.component';
import { WideBannerSectionComponent } from './components/wide-banner-section/wide-banner-section.component';
import { AppSectionTemplateService } from './services/section-template.service';
import { OpeningHourComponent } from './shared-components/opening-hours/opening-hour.component';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './../assets/i18n/app-shared-template', '.json');
}

@NgModule({
	declarations: [
		HeaderMenuComponent,
		FooterMenuComponent,
		TopBannerComponent,
		TopBannerCarouselComponent,
		OutletInfoComponent,
		ContactUsComponent,
		RestaurantMenuSectionComponent,
		MenuPlanComponent,
		OutletManagerComponent,
		WideBannerSectionComponent,
		ModalDialogComponent,
		RestaurantMenuFoodItemComponent,
		TextOnlySectionComponent,
		OutletListComponent,
		BodySectionComponent,
		FoodOriginSection,
		LeftImageCardSectionComponent,
		RightImageCardSectionComponent,
		FeedbackComponent,
		SkeletonLoader,
		GetFoodItemTypeIcon,
		PriceFormatterPipe,
		GetTranslationPipe,
		IsNutritionalValueExistPipe,
		HeroSectionComponent,
		DescriptionSectionComponent,
		VideoDescriptionSectionComponent,
		ContactUsCopyComponent,
		BodySectionCopyComponent,
		FooterSectionComponent,
		VideoSectionComponent,
		IngredientsViewModalComponent,
		CustomToolTipComponent,
		ToolTipRendererDirective,
		OpeningHourComponent,
	],
	imports: [
		CommonModule,
		MaterialModule,
		FlexLayoutModule.withConfig({
			useColumnBasisZero: false,
			printWithBreakpoints: [
				'xs',
				'sm',
				'md',
				'lg',
				'xl',
				'lt-sm',
				'lt-md',
				'lt-lg',
				'lt-xl',
				'gt-xs',
				'gt-sm',
				'gt- md',
				'gt-lg',
			],
		}),
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			isolate: true,
		}),
		SharedDirectiveModule,
		GoogleMapsModule,
		MatExpansionModule,
		SharedModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		AppOverlayModule,
	],
	exports: [
		CommonModule,
		MaterialModule,
		FlexLayoutModule,
		TranslateModule,
		MatExpansionModule,
		SharedDirectiveModule,
		GoogleMapsModule,
		SharedModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		AppOverlayModule,
		ModalDialogComponent,
		HeaderMenuComponent,
		FooterMenuComponent,
		TopBannerComponent,
		TopBannerCarouselComponent,
		OutletInfoComponent,
		ContactUsComponent,
		RestaurantMenuSectionComponent,
		MenuPlanComponent,
		OutletManagerComponent,
		WideBannerSectionComponent,
		OutletListComponent,
		BodySectionComponent,
		FoodOriginSection,
		LeftImageCardSectionComponent,
		RightImageCardSectionComponent,
		TextOnlySectionComponent,
		FeedbackComponent,
		RestaurantMenuFoodItemComponent,
		SkeletonLoader,
		GetFoodItemTypeIcon,
		PriceFormatterPipe,
		GetTranslationPipe,
		IsNutritionalValueExistPipe,
		VideoSectionComponent,
		FooterSectionComponent,
	],
	providers: [
		AppSectionTemplateService,
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'DD.MM.YYYY',
				},
				display: {
					dateInput: 'DD.MM.YYYY',
					monthYearLabel: 'MMM YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
				},
			},
		},
	],
})
export class SectionTemplateModule {
	public static components = {
		'19b08e8f-cb82-4f4d-96bb-dcf82ba6c223': HeaderMenuComponent,
		'3f821d9d-b4f0-4e72-8e7c-dc8d3e035db7': TopBannerComponent,
		'35cbb15b-7c2c-4cc5-bba1-9869a008fe0a': TopBannerCarouselComponent,
		'35cbb15b-7c2c-4cc5-bba1-9869a008fe0e': FooterMenuComponent,
		'76754d95-0313-4e2d-8e4f-fe045fbaf5a0': OutletInfoComponent,
		'ce4b649e-a6cb-44f7-b87a-07db91c66e91': ContactUsComponent,
		'35cbb15b-7c2c-4cc5-bba1-9869a008fe0c': RestaurantMenuSectionComponent,
		'860c10e9-3158-4a6e-8e27-af951da4739a': MenuPlanComponent,
		'13bd7d8b-41fe-47a3-966b-3f7f1714f26c': LeftImageCardSectionComponent,
		'd34e1ab8-14b5-4d95-8bb8-f0c74b1b64c2': RightImageCardSectionComponent,
		'06c83fa4-853c-4aa2-9d50-fd59c21704de': OutletManagerComponent,
		'13bd7d8b-41fe-47a3-966b-3f7f1714f26d': WideBannerSectionComponent,
		'13bd7d8b-41fe-47a3-966b-3f7f1714f26e': FoodOriginSection,
		'35cbb15b-7c2c-4cc5-bba1-9869a008fe0b': OutletListComponent,
		'13bd7d8b-41fe-47a3-966b-3f7f1714f26b': BodySectionComponent,
		'0a8f0af4-c1f1-4d8a-b3b8-145c26c0d541': TextOnlySectionComponent,
		'19bdd6d4-d453-4674-bb33-f57f3459819c': FeedbackComponent,
		'32ce63b3-ed76-43b2-937e-734a6931d7e3': HeroSectionComponent,
		'2acd189f-6033-485a-ac06-b93ed99b4515': BodySectionCopyComponent,
		'eabe2a13-dd63-4790-9c93-3c327ef60363': DescriptionSectionComponent,
		'8412720c-291e-4873-8e77-06180583c343': VideoDescriptionSectionComponent,
		'3b26c9bf-4cf5-46fc-8e8c-d87bda62ead9': ContactUsCopyComponent,
		'da95a5b8-1a6d-46dc-a1af-f15ecc786c3e': FooterSectionComponent,
	};

	public static Category = {
		'19b08e8f-cb82-4f4d-96bb-dcf82ba6c223': HeaderMenuComponent,
		Hero_Section: TopBannerComponent,
		Hero_Carousel_Section: TopBannerCarouselComponent,
		'35cbb15b-7c2c-4cc5-bba1-9869a008fe0e': FooterMenuComponent,
		About_Us_Section: OutletInfoComponent,
		Contact_Us_Section: ContactUsComponent,
		Restaurant_Menu_Section: RestaurantMenuSectionComponent,
		Menu_Plan_Section: MenuPlanComponent,
		Left_Image_Card_Section: LeftImageCardSectionComponent,
		Right_Image_Card_Section: RightImageCardSectionComponent,
		Outlet_Information_Section: OutletManagerComponent,
		Wide_Banner_Section: WideBannerSectionComponent,
		'3_Card_Section': FoodOriginSection,
		Outlet_List_Section: OutletListComponent,
		Body_Section: BodySectionComponent,
		Text_Only_Section: TextOnlySectionComponent,
		Feedback_Section: FeedbackComponent,
		'32ce63b3-ed76-43b2-937e-734a6931d7e3': HeroSectionComponent,
		'2acd189f-6033-485a-ac06-b93ed99b4515': BodySectionCopyComponent,
		'eabe2a13-dd63-4790-9c93-3c327ef60363': DescriptionSectionComponent,
		'8412720c-291e-4873-8e77-06180583c343': VideoDescriptionSectionComponent,
		'3b26c9bf-4cf5-46fc-8e8c-d87bda62ead9': ContactUsCopyComponent,
		'da95a5b8-1a6d-46dc-a1af-f15ecc786c3e': FooterSectionComponent,
		Video_Section: VideoSectionComponent,
		Footer_Section: FooterSectionComponent,
	};
}

// empty push
