import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IFoodItemType, foodItemType } from '../../../models/microsite-frontend.model';
import { IngredientsViewModalComponent } from '../ingredients-view-modal/ingredients-view-modal.component';
import { FoodItem, NutritientValueList, OriginOfFoodDetail, Price } from '../interface/restaurant-menu.interface';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';

@Component({
	selector: 'app-restaurant-menu-food-item',
	templateUrl: './restaurant-menu-food-item.component.html',
	styleUrls: ['./restaurant-menu-food-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantMenuFoodItemComponent implements OnInit {
	@Input() menuItem: FoodItem;
	@Input() isMobileView: boolean;
	@Input() currentLang: string = 'en';
	foodItemIngredientDisplay: { isDisplaying: boolean; item: 'allergic' | 'nutrition' | 'additive' | null } = {
		isDisplaying: false,
		item: null,
	};
	isOnlyAlleginItem: boolean = false;
	nutritionValueList: any;
	tooltipData: any;
	isContainAllergin: boolean = false;
	isContainNutrition: boolean = false;
	isContainAdditives: boolean = false;

	constructor(public dialog: MatDialog, private _router: Router) {}

	ngOnInit(): void {
		this.menuItem.FoodItem.Price = this.menuItem?.FoodItem?.Price.sort(this.compareByType);
		this.isOnlyAlleginItem = this.checkAustrianTemplate();
		this.checkFoodTypes();
	}

	checkFoodTypes(): void {
		this.isContainAllergin = this?.menuItem?.AllergicInfoDetails?.length > 0;
		this.isContainAdditives = this?.menuItem?.AdditiveInfoDetails?.length > 0;
		this.isContainNutrition =
			this?.menuItem?.FoodItem?.Translations?.find((item) => item.Language == this.currentLang)
				?.NutritientValueList?.length > 0 || this?.menuItem?.FoodItem?.NutritientValueList?.length > 0;
	}

	compareByType(A, B) {
		return B.PriceType < A.PriceType ? -1 : B.PriceType > A.PriceType ? 1 : 0;
	}

	checkAustrianTemplate() {
		return (
			this._router.url.includes('template-preview') &&
			this._router.url.includes('a8dafeb9-fcc7-4c8b-88ff-c5b673a12c07')
		);
	}

	toggleDisplayNutrition(item: 'allergic' | 'nutrition' | 'additive' | null): void {
		if (this.foodItemIngredientDisplay.isDisplaying && this.foodItemIngredientDisplay.item === item) {
			this.foodItemIngredientDisplay.isDisplaying = false;
			this.foodItemIngredientDisplay.item = item;
			return;
		}
		this.foodItemIngredientDisplay.isDisplaying = true;
		this.foodItemIngredientDisplay.item = item;
	}

	openFoodMenuModal(type: string, menuItem): void {
		this.nutritionValueList = menuItem;
		this.dialog.open(IngredientsViewModalComponent, {
			width: '439px',
			maxWidth: '92vw',
			data: {
				info: menuItem,
				type: type,
				currentLang: this.currentLang,
				foodItemTitle: this.menuItem?.FoodItem?.FoodItemTitle,
				selectedTab: type,
				isContainAllergin: this.isContainAllergin,
				isContainAdditives: this.isContainAdditives,
				isContainNutrition: this.isContainNutrition,
			},
			scrollStrategy: new NoopScrollStrategy(),
			panelClass: 'ingredient-view-modal',
		});
	}

	openDialog(title: string): void {
		let category = foodItemType.find((item) => item.typeName === title.toLocaleLowerCase());
		this.dialog.open(ModalDialogComponent, {
			width: '439px',
			data: {
				title: category.title,
				description: category.description,
			},
		});
	}

	mouseOverEvent(row: any) {
		this.tooltipData = foodItemType.find((item) => item.typeName.toLowerCase() == row.toLowerCase());
	}

	getIcon(type: string): IFoodItemType['icon'] {
		return foodItemType.find((item) => item.typeName.toLowerCase() === type.toLowerCase())?.icon;
	}

	priceTrackByFn(index: number, item: Price): string {
		return item.Currency;
	}
	nutritionTrackByFn(index: number, item: NutritientValueList): string {
		return item.NutrientName;
	}
	trackByFn(index: number, item: OriginOfFoodDetail): string {
		return item.ItemId;
	}
	trackByIndex(index: number, item: OriginOfFoodDetail): string {
		return index.toString();
	}
}
