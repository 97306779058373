import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-description-section',
	templateUrl: './description-section.component.html',
	styleUrls: ['./description-section.component.scss'],
})
export class DescriptionSectionComponent implements OnInit {
	@Input('imageFiles') imageFiles: any[] = [
		{
			FileUrl: '../../../assets/images/description-image-1.png',
		},
	];
	@Input('mainTitle') mainTitle: string = 'Das Betriebsrestaurant - Dein “Third Place”';
	@Input('bodyText') bodyText: string =
		"<div><strong>Hier fühlst Du dich wohl. Leckeres Essen. Erstklassige Qualität. Gesunde Frische und gute Stimmung. Ein Treffpunkt - nicht nur zum Essen, sondern auch für Gespräche und Kontakte.</strong> <br> <br> Im Mittelpunkt unserer Dienstleistung steht ein gesundes und attraktives Gastronomieangebot - vom Morgenkaffee über das frisch zubereitete Mittagessen bis zum Catering bei Meetings oder Workshops. Kennzeichnend für unser Angebot ist die Vielfalt'</div>";
	@Input('form') form: any = null;
	@Input('buttonText') buttonText: string = 'Jetzt Kontakt aufnehmen';
	@Input('isMobileView')
	isMobileView: boolean = false;
	@Input('buttonURL') buttonURL: string = 'https://www.sv-group.de/de/kontakt/';

	ImageFilesForm: any = null;
	mainTitleForm: any = null;
	bodyTextForm: any = null;
	buttonTextForm: any = null;
	buttonUrlForm: any = null;

	constructor(private appSectionTemplateService: AppSectionTemplateService, @Inject(DOCUMENT) private _document: Document) {}
	ngOnInit(): void {
		if (this.form !== null) {
			this.form.MetadataList.controls[0].controls.forEach((item) => {
				if (item.controls.PropertyKey.value === 'ImageFiles') {
					this.ImageFilesForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'MAIN_TITLE') {
					this.mainTitleForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'SUB_TITLE') {
					this.bodyTextForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'BUTTON_TEXT') {
					this.buttonTextForm = item.controls;
				} else if (item.controls.PropertyKey.value === 'BUTTON_URL') {
					this.buttonUrlForm = item.controls;
				}
			});
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'card-template');
		}

		if (this.ImageFilesForm === null) {
			this.ImageFilesForm = this.appSectionTemplateService.createPropertyFormGroup(this.imageFiles).controls;
		}

		if (this.mainTitleForm === null) {
			this.mainTitleForm = this.appSectionTemplateService.createPropertyFormGroup(this.mainTitle).controls;
		}

		if (this.bodyTextForm === null)
			this.bodyTextForm = this.appSectionTemplateService.createPropertyFormGroup(this.bodyText).controls;

		if (this.buttonTextForm === null)
			this.buttonTextForm = this.appSectionTemplateService.createPropertyFormGroup(this.buttonText).controls;

		if (this.buttonUrlForm === null)
			this.buttonUrlForm = this.appSectionTemplateService.createPropertyFormGroup(this.buttonURL).controls;
	}

	onLinkToClick(url) {
		let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

		if (!/^http[s]?:\/\//.test(url)) {
			url = 'http://' + url.replaceAll(htmlRegexG, '');
		}
		const link = this._document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.click();
		link.remove();
	}
}
