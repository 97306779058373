<div
	id="footer-top"
	style="scroll-margin-top: 88px"
	fxLayout="row"
	fxLayoutAlign="center center"
	[class.is-edit]="this.form?.IsOnEdit.value"
>
	<div
		[class.hide-div]="isMobileView"
		[class.hide-tablet-div]="isMobileView"
		class="banner"
		fxFlex="100%"
		fxLayout="row"
		fxLayoutAlign="center center"
		ngClass.sm="hide-tablet-div"
		ngClass.xs="hide-div"
	>
		<div class="icon mr-48" fxLayout="row" fxLayoutAlign="center center"><img [src]="iconSrc" alt="icon" /></div>
		<div fxLayout="row wrap" class="contents" fxLayoutGap="48px">
			<div class="list quick-link">
				<p class="footer__header">{{ 'QUICK_LINKS' | translate }}</p>
				<p *ngFor="let pageName of micrositePageList" class="link" (click)="goToPageLinks(pageName)">
					{{ pageName | translate }}
				</p>
			</div>
			<div class="list external-link">
				<p class="footer__header">{{ 'EXTERNAL_LINKS' | translate }}</p>
				<p *ngFor="let pageName of globalPageList" class="link" (click)="goToPageLinks(pageName)">
					{{ pageName | translate }}
				</p>
			</div>
			<div class="list opening-hour" *ngIf="openingHourList.length > 0">
				<p class="footer__header">{{ 'OPENING_HOURS' | translate }}</p>
				<div fxLayout="row wrap" fxLayoutGap="30px">
					<div class="opening-hour-list" *ngFor="let item of openingHourList">
						<div fxLayout="column">
							<p class="name">{{ item.OutletName }}</p>
							<div *ngFor="let schedule of item.Schedules">
								<p class="day">{{ schedule.WeekDay }}</p>
								<div fxLayout="row">
									<mat-icon class="material-symbols-outlined">schedule </mat-icon>
									<p class="hour">{{ schedule.OpeningTime + ' - ' + schedule.ClosingTime }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="list"
				*ngIf="
					faceBookUrlForm.PropertyValue.value.length > 0 ||
					instagramUrlForm.PropertyValue.value.length > 0 ||
					youtubeUrlForm.PropertyValue.value.length > 0
				"
			>
				<p class="footer__header">{{ 'SOCIAL_MEDIA' | translate }}</p>
				<div class="social-media" fxLayout="row" fxLayoutGap="10px">
					<img
						*ngIf="faceBookUrlForm.PropertyValue.value.length > 0"
						class="facebook"
						src="../../../../../assets/icons/facebook.svg"
						(click)="goToSocialLink(faceBookUrlForm.PropertyValue.value)"
						alt="icon"
					/>
					<img
						*ngIf="instagramUrlForm.PropertyValue.value.length > 0"
						class="instagram"
						src="../../../../../assets/icons/instagram.svg"
						(click)="goToSocialLink(instagramUrlForm.PropertyValue.value)"
						alt="icon"
					/>
					<img
						*ngIf="youtubeUrlForm.PropertyValue.value.length > 0"
						class="youtube"
						src="../../../../../assets/icons/youtube.svg"
						(click)="goToSocialLink(youtubeUrlForm.PropertyValue.value)"
						alt="icon"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="footer-bottom" [class.mobile-display]="isMobileView" fxLayout="row" fxLayoutAlign="center center">
	<mat-icon class="material-symbols-outlined">copyright</mat-icon>
	<p>{{ copyRightYearstring + ' ' + micrositeName }}</p>
</div>
