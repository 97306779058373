import { Component, Input, OnInit } from "@angular/core";
import { AppSectionTemplateService } from "../../services/section-template.service";

@Component({
  selector: "app-text-only-section",
  templateUrl: "./text-only-section.component.html",
  styleUrls: ["./text-only-section.component.scss"],
})
export class TextOnlySectionComponent implements OnInit {
  @Input("isMobileView") isMobileView: boolean = false;
  @Input("form") form: any = null;

  @Input("text") text: string =
    "<p>You are on the website of a catering business of SV (Switzerland) AG or one of its subsidiaries. SV (Switzerland) AG is a company of the SV Group. The SV Group is a service company with core competencies in the areas of catering and hotel management, operating in Switzerland, Germany and Austria.</p></br><p>The content used on this site is the property of SV (Switzerland) AG and may be protected by copyright and/or trademark law or otherwise.</p>";
  detailsTextForm: any = null;

  constructor(private appSectionTemplateService: AppSectionTemplateService) {}

  ngOnInit(): void {
    this.processTextAreaData();
  }

  processTextAreaData(): void {
    if (this.form != null) {
      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form.IsOnEdit,
        "text-only"
      );
      this.form.MetadataList.controls[0].controls.forEach((item) => {
        if (item.controls.PropertyKey.value == "TEXT")
          this.detailsTextForm = item.controls;
      });
    }
    if (this.detailsTextForm == null)
      this.detailsTextForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.detailsTextForm
        ).controls;
  }
}
