export interface IOpeningHour {
	OutletName: string;
	Schedules: ISchedules[];
}

export interface ISchedules {
	WeekDay: string;
	IsOpen: boolean;
	OpeningTime: string;
	ClosingTime: string;
}

export interface IFeedbackData {
	Feedback: string;
	Firstname: string;
	LastName: string;
	Email: string;
	Phone: string;
	Rating: number;
	MicrositeId: string;
	RestaurantName: string;
	Language: string;
	OutletId: string;
}

export interface IOutletTranslation {
	DayOfWeek: string;
	Description: string;
	Language: string;
}

export interface IFoodItemTranslation {
	Title: string;
	Description: string;
	Language: string;
}

export const OpeningHourData = [
	{
		OutletName: 'Chreis 14',
		Schedules: [
			{
				WeekDay: 'Montag - Freitag',
				IsOpen: true,
				OpeningTime: '11.30',
				ClosingTime: '13.30',
			},
			{
				WeekDay: 'Samstag',
				IsOpen: true,
				OpeningTime: '11.30',
				ClosingTime: '15.30',
			},
		],
	},
	{
		OutletName: 'Cafeteria BelleVUE',
		Schedules: [
			{
				WeekDay: 'Montag - Sonntag',
				IsOpen: true,
				OpeningTime: '09.00',
				ClosingTime: '16.30',
			},
		],
	},
];

export interface IRestaurantInfo {
	Name: string;
	ImageUrl: string;
	ManagerInfo: {
		Name: string;
		Email: string;
		Details: string;
	};
	Description: string;
	OpeningHours: IOpeningHour;
	Services: string[];
}

export enum PageIndex {
	'home',
	'about-us',
	'fresh-healthy',
}

export const RestaurantData = [
	{
		Name: 'Chreis 14',
		ImageUrl:
			'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1240w,f_auto,q_auto:best/newscms/2021_10/1684857/indoor-dining-te-main-210309.jpg',
		ManagerInfo: {
			Name: 'Jessica Thiede',
			Email: 'circle@sv-group.com',
			Details:
				'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
		},
		Description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		OpeningHours: {
			OutletName: 'chris 14',
			Schedules: [
				{
					Day: 'Montag - Freitag',
					Hours: ['11.30 - 13.30 Uhr'],
				},
			],
		},
		Services: ['Öffentlich', '350 Plätze', 'Take Away-Angebot', 'Parkplätze vorhanden'],
	},
	{
		Name: 'Cafeteria BelleVUE',
		ImageUrl:
			'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1240w,f_auto,q_auto:best/newscms/2021_10/1684857/indoor-dining-te-main-210309.jpg',
		ManagerInfo: {
			Name: 'Tomas Alvas',
			Email: 'circle@sv-group.com',
			Details:
				'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
		},
		Description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		OpeningHours: {
			OutletName: 'Cafeteria BelleVUE',
			Schedules: [
				{
					Day: 'Dienstag Freitag',
					Hours: ['14:00 - 17:00 Uhr'],
				},
				{
					Day: 'Samstag Sonntag',
					Hours: ['15:30 - 20:30 Uhr'],
				},
			],
		},
		Services: ['Öffentlich', '350 Plätze', 'Take Away-Angebot', 'Parkplätze vorhanden'],
	},
];

export interface IFoodItemType {
	typeName: string;
	title: string;
	description: string;
	icon: string;
}

export const foodItemType: IFoodItemType[] = [
	{
		typeName: 'vegetarisch',
		title: 'VEGETARIAN',
		description: 'VEGETARISH_DESCRIPTION',
		icon: '../../../../../../assets/icons/vegetarish_icon.svg',
	},
	{
		typeName: 'vegan',
		title: 'VEGAN',
		description: 'VEGAN_DESCRIPTION',
		icon: '../../../../../../assets/icons/vegan_icon.svg',
	},
	{
		typeName: 'promotion',
		title: 'PROMOTIONAL_MENU',
		description: 'PROMOTIONAL_MENU_DESCRIPTION',
		icon: '../../../../../../assets/icons/promo.svg',
	},
	{
		typeName: 'glutenfrei',
		title: 'GLUTENFREI',
		description: 'GLUTENFREI_DESCRIPTION',
		icon: '../../../../../../assets/icons/glutenfrei.svg',
	},
	{
		typeName: 'laktosefrei',
		title: 'LAKTOSE_FREE',
		description: 'LAKTOSE_FREE_DESCRIPTION',
		icon: '../../../../../../assets/icons/laktosefrei.svg',
	},
	{
		typeName: 'enthält schweinefleisch',
		title: 'PORK',
		description: 'PORK_DESCRIPTION',
		icon: '../../../../../../assets/icons/pork.svg',
	},
];
