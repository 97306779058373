<div
	*ngIf="isPreview"
	id="top-banner"
	ngClass.sm="tablet-display"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="form?.IsOnEdit.value"
	style="scroll-margin-top: 88px"
	[class.top-margin]="true"
>
	<div class="slideshow-container">
		<div fxLayout="row-reverse">
			<img
				ngClass.lt-md="hide-div"
				src="../../../assets/images/sv-icon.png"
				class="sv-logo"
				alt=""
				(click)="pageReload()"
			/>

			<div ngClass.gt-sm="hide-div" class="bg-white"></div>
			<img
				ngClass.gt-sm="hide-div"
				src="../../../assets/images/hero-section-logo-mobile.svg"
				class="sv-logo"
				alt=""
				(click)="pageReload()"
			/>

			<div *ngIf="languages.length > 1" class="sections language-section" fxLayout="row" (click)="onOpenLanguageList()">
				<p class="sections language-key">{{ selectedLanguage | uppercase }}</p>
				<img class="drop-down-icon" src="../../../assets/icons/arrow_drop_down_24px.svg" alt="" />
				<div
					class="language-list"
					*ngIf="isOpenLanguageList"
					(clickOutside)="
						onClickedOutside($event, ['lang-key', 'language-key', 'drop-down-icon', 'sections', 'language-section'])
					"
				>
					<p
						class="lang-key"
						fxLayout="column"
						fxLayoutAlign="center center"
						(click)="onLanguageSelect(language)"
						*ngFor="let language of languages; let i = index"
					>
						<span> {{ language | uppercase }} </span>
						<span *ngIf="i + 1 < languages?.length" class="divider"></span>
					</p>
				</div>
			</div>
		</div>

		<div
			style="scroll-margin-top: 88px"
			*ngFor="let slide of slideCount"
			class="mySlides"
			[class.opacity]="currentSlideIndex.value === slide"
		>
			<div class="slide">
				<img
					class="animation"
					src="{{ imageLinksForm[slide]?.PropertyValue?.value[0]?.FileUrl || link }}"
					alt="image"
					fetchpriority="high"
				/>

				<div class="content-wrapper content-position">
					<h2 class="main-title" [innerHTML]="mainTitleForm[slide]?.PropertyValue.value || mainTitle"></h2>
					<p class="sub-title mt-8" [innerHTML]="subTitleForm[slide]?.PropertyValue.value"></p>
					<button
						[innerHTML]="buttonTextForm[slide]?.PropertyValue?.value || buttonTextForm"
						*ngIf="buttonTextForm[slide]?.PropertyValue?.value?.length >= 1"
						mat-flat-button
						class="button mt-32"
						(click)="scrollToBodySection()"
					></button>
				</div>
			</div>
		</div>
		<div class="shadow-wrap"></div>
		<div class="controller content-padding" fxLayout="row" *ngIf="isPlatformBrowser && slideCount.length > 0">
			<div
				*ngFor="let slide of slideCount; let i = index"
				(click)="onImageSelect(i)"
				class="bullet"
				[class.active-bullet]="currentSlideIndex == slide"
				[class.inactive-bullet]="slideCount.length < 2"
			></div>
		</div>
	</div>
</div>
