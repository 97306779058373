import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AvatarModule } from 'ngx-avatar';
import { AppSnackbarComponent } from './components/app-snackbar/app-snackbar.component';
import { AppStarRatingComponent } from './components/app-star-rating/app-star-rating.component';
import { GenericInfoModalComponent } from './components/generic-info-modal/generic-info-modal.component';
import { SvProgressSpinnerComponent } from './components/sv-progress-spinner/sv-progress-spinner.component';
import { MaterialModule } from './material.module';
import { SvBusinessService } from './services/sv-business.service';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../../assets/i18n/generic-app/', '.json');
}

@NgModule({
	imports: [
		AvatarModule,
		CommonModule,
		MaterialModule,
		FlexLayoutModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			isolate: true,
		}),
	],
	declarations: [GenericInfoModalComponent, SvProgressSpinnerComponent, AppSnackbarComponent, AppStarRatingComponent],
	exports: [
		AvatarModule,
		CommonModule,
		MaterialModule,
		FlexLayoutModule,
		TranslateModule,
		SvProgressSpinnerComponent,
		AppStarRatingComponent,
		GenericInfoModalComponent,
	],
	entryComponents: [GenericInfoModalComponent],
	providers: [SvBusinessService],
})
export class SharedModule {}
