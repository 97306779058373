import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { IOpeningHour } from "../../models/microsite-frontend.model";
import { AppSectionTemplateService } from "../../services/section-template.service";
import { SvBusinessService } from "./../../../shared/services/sv-business.service";

@Component({
  selector: "app-footer-menu",
  templateUrl: "./footer-menu.component.html",
  styleUrls: ["./footer-menu.component.scss"],
})
export class FooterMenuComponent implements OnInit {
  @Input("copyRightYear") copyRightYearstring: any = new Date().getFullYear();
  @Input("footerText") footerText: string = "2022 Gastronomie Circle";
  @Input("micrositeName") micrositeName: string = "SV (Deutschland) GmbH";
  @Input("iconSrc") iconSrc: string =
    "../../../../../assets/icons/sv_restaurent_icon.svg";
  @Input("openingHourList") openingHourList: IOpeningHour[] = [];
  @Input("facebookUrl") facebookUrl: string = "";
  @Input("instagramUrl") instagramUrl: string = "";
  @Input("youtubeUrl") youtubeUrl: string = "";
  @Input("form") form: any = null;
  @Input("isMobileView") isMobileView: boolean = false;
  @Input("micrositePageList") micrositePageList: string[] = [];
  @Input("globalPageList") globalPageList: string[] = [];
  @Output("selectedPageName") selectedPageName: EventEmitter<string> =
    new EventEmitter();
  private _unsubscribeAll: Subject<void> = new Subject<any>();

  faceBookUrlForm: any = null;
  instagramUrlForm: any = null;
  youtubeUrlForm: any = null;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private appSectionTemplateService: AppSectionTemplateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _svBusinessService: SvBusinessService
  ) {}

  ngOnInit(): void {
    if (this.form != null) {
      this.form.MetadataList.controls[0].controls.forEach((item) => {
        if (item.controls.PropertyKey.value == "FACEBOOK_URL")
          this.faceBookUrlForm = item.controls;
        else if (item.controls.PropertyKey.value == "INSTAGRAM_URL")
          this.instagramUrlForm = item.controls;
        else if (item.controls.PropertyKey.value == "YOUTUBE_URL")
          this.youtubeUrlForm = item.controls;
      });
      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form?.IsOnEdit,
        "footer-top"
      );
    }

    if (this.faceBookUrlForm == null)
      this.faceBookUrlForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.facebookUrl
        ).controls;
    if (this.instagramUrlForm == null)
      this.instagramUrlForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.instagramUrl
        ).controls;
    if (this.youtubeUrlForm == null)
      this.youtubeUrlForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.youtubeUrl
        ).controls;

    this.appSectionTemplateService.goToHomeFromFeedback
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: boolean) => {
        if (res) {
          this.goToPageLinks("HOME");
          this.appSectionTemplateService.goToHomeFromFeedback.emit(false);
        }
      });

    this.appSectionTemplateService.goToDataProtectionFromFeedback
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: boolean) => {
        if (res) {
          this.openDataPolicy();
          this.appSectionTemplateService.goToDataProtectionFromFeedback.emit(
            false
          );
        }
      });
  }

  goToSocialLink(url: string) {
    if (!/^http[s]?:\/\//.test(url)) {
      url = "http://" + url;
    }

    const link = this._document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.click();
    link.remove();
  }

  openDisclaimer() {
    this.router.navigate(["disclaimer"], { relativeTo: this.activatedRoute });
    this.selectedPageName.emit("Disclaimer");
  }

  openDataPolicy() {
    this.router.navigate(["data-protection"], {
      relativeTo: this.activatedRoute,
    });
    this.selectedPageName.emit("Data-protection");
  }

  openFeedback(): void {
    this.router.navigate(["feedback"], { relativeTo: this.activatedRoute });
    this.selectedPageName.emit("Feedback");
  }

  goToPageLinks(pageName) {
    this.router.navigate(
      [this._svBusinessService.getUrlFromLanguageKey(pageName)],
      {
        relativeTo: this.activatedRoute,
      }
    );
    this.selectedPageName.emit(pageName);
  }
}
