import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppOverlayComponent, OverlayBodyCompnent } from './app-overlay.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
	declarations: [AppOverlayComponent, OverlayBodyCompnent],
	entryComponents: [OverlayBodyCompnent],
	imports: [CommonModule, TranslateModule, ReactiveFormsModule, OverlayModule, FlexLayoutModule],
	exports: [AppOverlayComponent],
})
export class AppOverlayModule {}
