import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-modal-dialog',
	templateUrl: './modal-dialog.component.html',
	styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
	dialogData = {
		title: '',
		description: '',
	};
	constructor(
		public dialogRef: MatDialogRef<ModalDialogComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.dialogData.title = data.title;
		this.dialogData.description = data.description;
	}

	ngOnInit(): void {}
}
