import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
	selector: 'app-custom-snack-bar',
	templateUrl: './app-snackbar.component.html',
	styleUrls: ['./app-snackbar.component.scss'],
})
export class AppSnackbarComponent {
	constructor(public snackBarRef: MatSnackBarRef<AppSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
