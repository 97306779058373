import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, first, map } from 'rxjs';
import { environment } from '../../../src/environments/environment';

import { ApiResponse } from '../../../src/app/shared/Interfaces/api-response.interface';
import { RestaurantMenu } from '../components/restaurant-menu-section/interface/restaurant-menu.interface';
import { IFeedbackData } from '../models/microsite-frontend.model';

const header = new HttpHeaders({
	'Content-Type': 'application/json',
});
const publicHeader = new HttpHeaders({
	'Content-Type': 'application/json',
	Badge: '6ty084c2-2e1c-451d-80b0-f182abbb9r31',
});
@Injectable()
export class AppSectionTemplateService {
	baseUrl: string = environment.SVCmsBusinessService;
	goToHomeFromFeedback: EventEmitter<boolean> = new EventEmitter();
	goToDataProtectionFromFeedback: EventEmitter<boolean> = new EventEmitter();
	languageChangeEvent: EventEmitter<string> = new EventEmitter();
	outletChangeDetails = {
		isLastOutlet: false,
		isChanged: false,
	};

	constructor(private http: HttpClient, private fb: FormBuilder) {}

	commonOptions: any = {
		headers: header,
		withCredentials: true,
		observe: 'response',
	};
	publicOptions: any = {
		headers: publicHeader,
		withCredentials: false,
		observe: 'response',
	};

	scrollToSelectedOutleName() {
		if (this.outletChangeDetails.isChanged) {
			let el = document.querySelector('.selected-outlet');
			const rect = el.getBoundingClientRect();
			if (rect.top < 0 || rect.bottom > window.innerWidth) {
				if (this.outletChangeDetails.isLastOutlet) el.scrollIntoView({ block: 'nearest', inline: 'end' });
				else el.scrollIntoView({ block: 'nearest', inline: 'center' });
			}
		}
	}

	createPropertyFormGroup(value) {
		return this.fb.group({
			PropertyValue: [value],
		});
	}

	getOutletMenuDetails(id: string): Observable<ApiResponse<RestaurantMenu>> {
		if (!id) {
			throw new Error('Outlet Id is required');
		}
		const url = this.baseUrl + `/CMS/GetMenuList?OutletId=${id}`;
		return this.http.get<ApiResponse<RestaurantMenu>>(url, this.publicOptions).pipe(
			first(),
			map((response: HttpEvent<ApiResponse<RestaurantMenu>>) => response['body'])
		);
	}

	watchAndMarkSectionWhileEdit(formControl, id) {
		formControl?.valueChanges.subscribe((value) => {
			if (value) {
				let el = document.getElementById(id);
				el.scrollIntoView(true);
			}
		});
	}

	sendFeedBack(feedBackData: IFeedbackData) {
		const url = this.baseUrl + `/CMS/SendFeedback`;
		return this.http.post<IFeedbackData>(url, feedBackData, this.publicOptions);
	}

	processFooterData(data, outletList) {
		let openingHourData: any = {};
		let footerSection = data.MicrositePageWithSectionsDtoList[0].Sections.find(
			(section) => section.SectionTemplateId == '35cbb15b-7c2c-4cc5-bba1-9869a008fe0e'
		);

		if (footerSection && footerSection?.Translation) {
			footerSection.Translation.forEach((item) => {
				openingHourData[`${item.Language}`] = [];
				item?.MetaData.forEach((metaData) => {
					let outletObj = {
						OutletName: metaData?.OutletMetadata?.OutletName,
						OutletId: metaData?.OutletId,
						Schedules: [],
						categoryHours: [],
					};
					metaData?.OutletMetadata?.OpeningHours.forEach((openingHour) => {
						let tmpObj = {
							WeekDay: openingHour.WeekDay,
							IsOpen: openingHour.IsOpen,
							OpeningTime: openingHour.OpeningTime,
							ClosingTime: openingHour.ClosingTime,
							CategoryName: openingHour.CategoryName,
						};

						outletObj.Schedules.push(tmpObj);
					});
					metaData?.OutletMetadata?.CategoryHours?.forEach((categoryHour) => {
						let tmpObj = {
							CategoryName: categoryHour?.CategoryName,
							OpeningTime: categoryHour?.OpeningTime,
							ClosingTime: categoryHour?.ClosingTime,
						};

						outletObj.categoryHours.push(tmpObj);
					});

					if (metaData?.OutletId) {
						if (outletObj.OutletName == '') {
							outletObj.OutletName = outletList.find((o) => o.Id == metaData.OutletId).Name;
						}

						openingHourData[`${item.Language}`].push(outletObj);
					} else console.error('There is no Outlet');
				});
				this.getProperSequenceData(
					openingHourData[`${item.Language}`],
					outletList.map((o) => o.Id)
				);
			});
		}

		return openingHourData;
	}

	getProperSequenceData(data, sequence) {
		data.sort((a, b) => {
			const aIndex = sequence.indexOf(a.OutletId);
			const bIndex = sequence.indexOf(b.OutletId);
			return aIndex - bIndex;
		});
	}
}
