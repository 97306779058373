import { Component, Input, OnInit } from "@angular/core";
import { AppSectionTemplateService } from "../../services/section-template.service";

@Component({
  selector: "app-right-image-card-section",
  templateUrl: "./right-image-card-section.component.html",
  styleUrls: ["./right-image-card-section.component.scss"],
})
export class RightImageCardSectionComponent implements OnInit {
  @Input("imageFiles") imageFiles: any = {
    FileId: "",
    FileUrl: "../../../../assets/static-image/card-image-2.svg",
  };
  @Input("mainTitle") mainTitle: string =
    "Fair trade: Für die Umwelt und die Menschen";
  @Input("subTitle") subTitle: string =
    "Bei Produkten und Rohstoffen aus dem Süden setzen wir auf faire Arbeitsbedingungen. Deshalb finden Sie bei uns verschiedene Lebensmittel und Getränke ausnahmslos in Fairtrade-Qualität. Dazu gehören der SV Ice Tea, die SV Lemonade, Orangensaft und Bodyguard von Michel sowie die Zuckersticks. Sie finden hier auch Bio-Bananen aus fairem Handel.";
  @Input("form") form: any = null;
  @Input("isMobileView") isMobileView: boolean = false;

  ImageFilesForm: any = null;
  mainTitleForm: any = null;
  subTitleForm: any = null;

  constructor(private appSectionTemplateService: AppSectionTemplateService) {}
  ngOnInit(): void {
    if (this.form !== null) {
      this.form.MetadataList.controls[0].controls.forEach((item) => {
        if (item.controls.PropertyKey.value === "ImageFiles") {
          this.ImageFilesForm = item.controls;
        } else if (item.controls.PropertyKey.value === "MAIN_TITLE") {
          this.mainTitleForm = item.controls;
        } else if (item.controls.PropertyKey.value === "SUB_TITLE") {
          this.subTitleForm = item.controls;
        }
      });
      this.appSectionTemplateService.watchAndMarkSectionWhileEdit(
        this.form?.IsOnEdit,
        "card-template-2"
      );
    }

    if (this.ImageFilesForm === null) {
      this.ImageFilesForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.imageFiles
        ).controls;
    }

    if (this.mainTitleForm === null) {
      this.mainTitleForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.mainTitle
        ).controls;
    }

    if (this.subTitleForm === null)
      this.subTitleForm =
        this.appSectionTemplateService.createPropertyFormGroup(
          this.subTitle
        ).controls;
  }
}
